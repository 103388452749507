import React from "react";
import { AuthProvider } from "./context/AuthContext";

// Desktop 3 component
import Desktop3 from "./components/Desktop-3";
import Desktop4 from "./components/Desktop4";

import Frame3 from "./components/Frames/Frame-3";
import Frame10 from "./components/Frames/Frame-10";
import Frame11 from "./components/Frames/Frame-11";
import Frame12 from "./components/Frames/Frame-12";
import Frame18 from "./components/Frames/Frame-18";
import Refund from "./components/Desktop1/refund";
import Terms from "./components/Desktop1/terms_conditions";
import PrivacyPolicy from "./components/Desktop1/PrivacyPolicy";
import PricingPage from "./components/Payment";

import Section2 from "./components/Sections/section2";
import Section3 from "./components/Sections/section3";
import Section4 from "./components/Sections/section4";
import Section5 from "./components/Sections/section5";
import Section6 from "./components/Sections/section6";
import Section7 from "./components/Sections/section7";
import Section8 from "./components/Sections/section8";
import AdminSection3 from "./components/admin_section3";
import Admin from "./components/Admin";
import NotFound from "./components/UnknowRoute/NotFound";
import { ToastContainer } from "react-toastify";
import ContactUs from "./components/Desktop1/ContactUs";
import Payment from "./components/Payment";
import Desktop5 from "./components/Desktop-5";
import {
  requestPermission,
  // handleForegroundNotifications,
  onMessageListener,
} from "./components/firebase";
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Cards2 from "./components/Desktop1/Desktop-1"; // Import the Cards2 component

const App = () => {
  useEffect(() => {
    requestPermission();

    if (Notification.permission === "granted") {
      onMessageListener()
        .then((payload) => {
          // Determine whether to use `notification` or `data`
          const title =
            payload.notification?.title || payload.data?.title || "Hirers";
          const options = {
            body:
              payload.notification?.body ||
              payload.data?.body ||
              "Be the first to know about the latest jobs and opportunities.",
            icon: payload.notification?.image || payload.data?.image,
          };
          // Show the notification
          new Notification(title, options);
        })
        .catch((err) => console.error("Error receiving notification:", err));
    }
  }, []);

  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/Contact" element={<ContactUs />} />
          <Route path="/refund_policy" element={<Refund />} />
          <Route path="/terms_conditions" element={<Terms />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/pricing_page" element={<PricingPage />} />
          <Route path="/" element={<Cards2 />} />{" "}
          <Route path="/employee_login" element={<Desktop3 />} />{" "}
          <Route path="/postjob" element={<Desktop4 />} />{" "}
          <Route path="/edit/:jobId" element={<Desktop5 />} />
          <Route path="/Frame-3" element={<Frame3 />} />
          <Route path="/jobsearch" element={<Frame10 />} />{" "}
          <Route path="/candidate_login" element={<Frame11 />} />{" "}
          <Route path="/job_details/:jobId" element={<Frame12 />} />
          <Route path="/employee_dashboard" element={<Frame18 />} />{" "}
          <Route path="/applyform/:jobId" element={<Section2 />} />
          <Route path="/responses/:jobId" element={<Section3 />} />
          <Route path="/check_responses/:jobId" element={<AdminSection3 />} />
          <Route path="/first_details" element={<Section4 />} />{" "}
          <Route path="/section-5" element={<Section5 />} />{" "}
          <Route path="/candidate_profile" element={<Section6 />} />{" "}
          <Route path="/employee_profile" element={<Section7 />} />{" "}
          <Route path="/postedjobinfo" element={<Section8 />} />
          <Route path="/payment_gateway" element={<Payment />} />
          <Route path="/Admin" element={<Admin />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </AuthProvider>
    </Router>
  );
};

export default App;
