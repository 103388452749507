import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import { Link } from "react-router-dom";

const socialData = [
  {
    id: 1,
    title: "facebook",
    icon: <FaFacebook />,
    path: "https://www.facebook.com/hirers.co.in/",
  },
  {
    id: 2,
    title: "instagram",
    icon: <FaInstagram />,
    path: "https://www.instagram.com/hirers.co.in/",
  },
  {
    id: 3,
    title: "whatsapp",
    icon: <FaWhatsapp />,
    path: "https://chat.whatsapp.com/IyKRqBudFmg7ViOqQrJ9gr",
  },
  {
    id: 4,
    title: "linkedin",
    icon: <FaLinkedin />,
    path: "https://www.linkedin.com/company/hirers-co-in/?viewAsMember=true",
  },
];

const footerLinks = [
  { id: 1, title: "Home", path: "/" },
  { id: 2, title: "About Us", path: "about-us", isInternal: true }, // Internal scrolling
  { id: 3, title: "Post Job", path: "/employee_login" },
  { id: 4, title: "Search Job", path: "/candidate_login" },
  { id: 5, title: "Contact Us", path: "/Contact" },
  { id: 6, title: "Refund Policy", path: "/refund_policy" },
  { id: 7, title: "Privacy Policy", path: "/privacy_policy" },
  { id: 8, title: "Pricing", path: "/pricing_page" },
  { id: 9, title: "Terms & Conditions", path: "/terms_conditions" },
];

const Footer = () => {
  const handleScroll = (e, path) => {
    e.preventDefault();
    const section = document.getElementById(path);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleNotification = async () => {
    if (Notification.permission === "granted") {
      new Notification("Notification", {
        body: "It is working",
        icon: "https://firebasestorage.googleapis.com/v0/b/hirers-testing2.firebasestorage.app/o/hirersLogo.png?alt=media&token=bdf6755b-5afd-46c2-93a9-57a0d5a285d2",
      });
    } else {
      alert("Notification Permission is not granterd");
    }
  };
  return (
    <section className=" -red-100 py-12 bg-blue-100 text-white">
      {/* ----------- container ---------- */}

      <div className=" xl:w-10/12 w-11/12 mx-auto flex flex-col md:flex-row gap-y-8 justify-between">
        {/* ------------ left part ------------- */}
        <div className="  flex flex-row md:flex-col gap-y-12 justify-between md:w-[30%] w-full">
          {/* ---------- logo/slogan part --------- */}

          <div className="flex xlg:gap-x-2 gap-x-1">
            {/* ----- logo ---- */}

            <div className="xlg:w-[80px] xlg:h-[80px] md:w-[70px] md:h-[70px] w-[80px] h-[80px]">
              <img src="/hirersLogo.png" alt="" className="w-full h-full" />
            </div>

            {/* ------ slogan ----- */}

            <div className="hidden xmd:flex flex-col font-semibold">
              <h2 className="xlg:text-[32px] text-2xl">HIRERS</h2>
              <p className="text-sm">Hiring means Hirers!!</p>
            </div>
          </div>

          {/* --------- social icon part ---------- */}

          <div className="flex flex-col font-semibold smd:pl-4 pr-6 md:pr-0 pt-4 md:pt-0 ">
            <p className="xlg:text-base sm:text-[15px] text-base pb-1 border-b border-white w-max">
              Follow us on Social Media
            </p>

            {/* ---------social icons ------ */}

            <div className="flex flex-row gap-x-4 items-center text-2xl pt-2">
              {socialData.map((data) => (
                <p
                  key={data.id}
                  className="text-white hover:text-yellow-100 transition-all duration-300"
                >
                  <Link to={data.path}>{data.icon}</Link>
                </p>
              ))}
            </div>
          </div>
        </div>

        {/* --------- right part ------------ */}
        <div className="  xl:w-[65%] md:w-[68%] w-full flex flex-col md:gap-y-0 gap-y-10 justify-between">
          {/* ---------- all links ----------- */}
          <div className="grid grid-cols-2 xxs:grid-cols-3 xmd:grid-cols-4 xlg:grid-cols-5 gap-y-4 gap-x-2 text-base font-semibold ">
            {footerLinks.map((data) => (
              <p
                key={data.id}
                className="text-white hover:text-yellow-100 transition-all duration-300"
              >
                {data.isInternal ? (
                  <a
                    href={`#${data.path}`}
                    onClick={(e) => handleScroll(e, data.path)}
                  >
                    {data.title}
                  </a>
                ) : (
                  <Link to={data.path}>{data.title}</Link>
                )}
              </p>
            ))}
          </div>

          {/* -------- rights reserved text ------------ */}

          <div className="flex sm:justify-end justify-center text-[13px] font-medium">
            <button onClick={handleNotification}>
              <p>© 2025 by Hirers - All Rights Reserved.</p>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
