import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import axios from "axios";
import { toast } from "react-toastify";
import Navemployee from "../Sections/Navbar-3&5/Navbar_employee";
import { FaAngleRight } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { FaMoneyBills } from "react-icons/fa6";
import { requestPermission } from "../firebase";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Frame10 = ({ applied = "false" }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  // eslint-disable-next-line
  const [allowRender, setAllowRender] = useState("start");

  // const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    category: "",
    location: "",
    company: "",
    educationQualification: "",
  });

  //for search job box

  const [searchTerms, setSearchTerms] = useState("");
  // const [searchJob, setSearchJob] = useState([]);

  const isApplied = applied === "true";
  if (applied !== "true") applied = "false";
  // applied = applied === true;

  useEffect(() => {
    requestPermission().then(async (notify_token) => {
      console.log("notify_token", notify_token);
      if (notify_token) {
        try {
          await axios.post(
            `${process.env.REACT_APP_PORT}/api/subscribe`,
            { type: process.env.REACT_APP_TYPE_EMPLOYER, token: notify_token },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } catch (error) {
          console.error("Error subscribing to notifications:", error);
        }
      }
    });
  }, [token]);

  useEffect(() => {
    let isMounted = true;
    const getJobs = async () => {
      try {
        const profileResponse = await axios.post(
          `${process.env.REACT_APP_PORT}/api/updateprofiledata`,
          { type: process.env.REACT_APP_TYPE_EMPLOYER },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!profileResponse.data.name) {
          navigate("/first_details");
          return;
        }

        // console.log("Apply in Frame -10 : ", applied)

        const jobsResponse = await axios.post(
          `${process.env.REACT_APP_PORT}/api/allJobs`,
          { apply: applied },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (isMounted) {
          setAllowRender(true);
          const jobData = jobsResponse.data;
          setJobs(jobData);
          setFilteredJobs(jobData);

          // Extract unique filter values
          setCategories([...new Set(jobData.map((job) => job.category))]);
          setLocations([...new Set(jobData.map((job) => job.jobLocation))]);
          setCompanies([...new Set(jobData.map((job) => job.companyName))]);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
        // toast.error("Please try again later.");
        if (!toast.isActive("invalid-credentials-toast")) {
          toast.error("Session Timeout Please Login Again", {
            toastId: "invalid-credentials-toast",
          });
        }
        localStorage.clear();
        navigate("/");
      } finally {
        setLoading(false);
      }
    };

    getJobs();

    return () => {
      isMounted = false;
    };
  }, [applied, token, navigate]);

  // useEffect(() => {
  //   let updatedJobs = jobs; // Start with all jobs

  //   // Step 1: Apply filters (category, location, company)
  //   // updatedJobs = updatedJobs.filter((job) => {
  //   //   return (
  //   //     (selectedFilters.category === "" || job.category === selectedFilters.category) &&
  //   //     (selectedFilters.location === "" || job.jobLocation === selectedFilters.location) &&
  //   //     (selectedFilters.company === "" || job.companyName === selectedFilters.company)
  //   //   );
  //   // });

  //   // // Step 2: Apply search filter on top of the filtered jobs
  //   // if (searchTerms === "") {

  //   // }else{
  //   //   updatedJobs = updatedJobs.filter((job) =>
  //   //     job.jobTitle.toLowerCase().includes(searchTerms.toLowerCase())
  //   //   );
  //   // }

  //   // setFilteredJobs(updatedJobs);
  // }, [searchTerms, selectedFilters, jobs]); // Depend on jobs, filters, and search terms

  useEffect(() => {
    let updatedJobs = jobs;
    const delayFilter = setTimeout(() => {
      updatedJobs = updatedJobs.filter((job) => {
        return (
          (selectedFilters.category === "" ||
            job.category === selectedFilters.category) &&
          (selectedFilters.location === "" ||
            job.jobLocation === selectedFilters.location) &&
          (selectedFilters.company === "" ||
            job.companyName === selectedFilters.company) &&
          (selectedFilters.educationQualification === "" ||
            job.educationQualification ===
              selectedFilters.educationQualification)
        );
      });

      // Step 2: Apply search filter on top of the filtered jobs
      if (searchTerms === "") {
      } else {
        updatedJobs = updatedJobs.filter((job) =>
          job.jobTitle.toLowerCase().includes(searchTerms.toLowerCase())
        );
      }

      setFilteredJobs(updatedJobs);
    }, 300); // 300ms delay

    return () => clearTimeout(delayFilter);
  }, [searchTerms, selectedFilters, jobs]);

  // const applyFilters = () => {
  //   const filtered = jobs.filter((job) => {
  //     return (
  //       (selectedFilters.category === "" ||
  //         job.category === selectedFilters.category) &&
  //       (selectedFilters.location === "" ||
  //         job.jobLocation === selectedFilters.location) &&
  //       (selectedFilters.company === "" ||
  //         job.companyName === selectedFilters.company)
  //     );
  //   });
  //   setFilteredJobs(filtered);
  // };

  const handleFilterChange = (filterType, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const educations = [
    {
      id: 1,
      title: "10th Passed",
      value: "10thPassed",
    },
    {
      id: 2,
      title: "12th Passed",
      value: "12thPassed",
    },
    {
      id: 3,
      title: "Graduated and above",
      value: "graduateAbove",
    },
  ];

  //no need for this

  // useEffect(() => {
  //   applyFilters();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedFilters]);

  // if (!allowRender) {
  //   console.log("I am here")
  //   return null; // Don't render anything until checks are done
  // }

  return (
    <>
      <div className="w-full overflow-x-hidden min-h-screen font-inter  ">
        {loading && !isApplied ? (
          <HashLoader
            color="#34a5f3"
            size={60}
            cssOverride={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            loading={true}
          />
        ) : (
          <div className="">
            {!isApplied && (
              <div className="">
                <div className="">
                  <Navemployee />
                </div>
                <div className="text-center text-white bg-primary-blue p-4 md:w-screen md:block hidden mb-4 rounded-b-xl ">
                  <h2 className="text-xl lg:text-3xl font-bold mt-1">
                    "GET HIRED IN 3 EASY STEPS"
                  </h2>

                  <div className="relative flex justify-evenly items-center mt-3 mb-1 lg:text-xl md:text-lg">
                    <div className="flex items-center relative">
                      <span>1) Select Job</span>
                    </div>

                    <div className="flex items-center lg:w-32">
                      <img
                        src="./Arrow1.png"
                        alt="Arrow"
                        className="h-10  transform -rotate-[30deg]"
                      />
                    </div>

                    <div className="flex flex-col items-center relative">
                      <span>2) Apply by filling basic details</span>
                    </div>

                    <div className="flex items-center lg:w-32">
                      <img
                        src="./Arrow1.png"
                        alt="Arrow"
                        className="h-10  transform -rotate-[30deg]"
                      />
                    </div>

                    <div className="flex flex-col items-center">
                      <span>3) Give interview and get hired easily</span>
                    </div>
                  </div>
                </div>

                <div className="w-full gap-x-8 mb-2 flex flex-col gap-y-6 justify-center items-center sm:hidden mt-4  ">
                  <div className=" flex flex-row  gap-x-4 items-center justify-center gap-y-2 ">
                    <h1 className="xxs:text-base text-sm font-bold text-blue-200 text-center update">
                      For daily job updates, follow us on
                    </h1>
                    <div className="flex gap-x-2 justify-center">
                      <a
                        href="https://chat.whatsapp.com/IyKRqBudFmg7ViOqQrJ9gr"
                        target="_blank"
                        rel="noopener noreferrer"
                        className=" w-7 h-7 rounded-full transition-all duration-300 hover:scale-110 cursor-pointer"
                      >
                        <LazyLoadImage
                          src="/whatsapp-1.png"
                          alt="whatsapp"
                          effect="blur"
                          className="w-full h-full object-cover rounded-full"
                        />
                      </a>

                      <a
                        href="https://chat.whatsapp.com/IyKRqBudFmg7ViOqQrJ9gr"
                        target="_blank"
                        rel="noopener noreferrer"
                        className=" w-7 h-7 rounded-full transition-all duration-300 hover:scale-110 cursor-pointer"
                      >
                        <LazyLoadImage
                          src="/teleg.png"
                          alt="whatsapp"
                          effect="blur"
                          className="w-full h-full object-cover rounded-full"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="flex items-center  justify-center xs:w-96 xsm:w-[93%] xxs:w-[95%]  w-[320px]   bg-blue-50  rounded-full px-2 py-1">
                    <FaSearch className="ml-2 h-5 w-5" />
                    <input
                      type="text"
                      // name="search for jobs"
                      placeholder="Search for jobs"
                      className="w-full placeholder-primary-blue placeholder-text-base  text-base  text-black bg-transparent border-none ml-4 bg-blue-50 focus:outline-none"
                      value={searchTerms}
                      onChange={(e) => setSearchTerms(e.target.value)}
                    />
                  </div>
                </div>

                {/* {error && <div className="text-red-500">{error}</div>} */}
                <div className=" flex xl:flex-row flex-col-reverse  gap-y-2 sm:justify-between items-center md:ml-12  mb-4 flex-wrap mt-2 xl:mr-12 sm:mr-2 ml-2  ">
                  <div className=" flex flex-wrap justify-center md:justify-start ">
                    {/* Category Filter */}
                    <button className="flex items-center text-[#393285] sm:px-4  px-2 py-2  rounded-lg mb-2 gap-4">
                      <div className=" bg-[#D9D9D9] w-7 h-7 md:h-9 md:w-10 flex justify-center items-center rounded-lg">
                        <FaFilter className=" md:h-5 md:w-5 text-black" />
                      </div>
                      <div className="bg-[#D9D9D9] md:ml-2  px-1 py-1 md:py-[5px] rounded-lg md:rounded-xl text-sm md:text-lg font-medium md:w-52 w-36">
                        <select
                          className="bg-[#D9D9D9] focus:outline-none w-full"
                          onChange={(e) =>
                            handleFilterChange("category", e.target.value)
                          }
                          value={selectedFilters.category}
                        >
                          <option value="" className="text-xsm md:text-sm">
                            All Categories
                          </option>
                          {categories.map((category, index) => (
                            <option
                              key={index}
                              value={category}
                              className="text-xs md:text-xs"
                            >
                              {category}
                            </option>
                          ))}
                        </select>
                      </div>
                    </button>

                    {/* Location Filter */}
                    <button className="flex items-center bg-gray-200 text-[#393285] sm:px-4  px-2 py-2 rounded-lg  mb-2">
                      {/* <RiArrowDownSLine /> */}
                      <div className="bg-[#D9D9D9] md:ml-2  px-1 py-1 md:py-[5px] rounded-lg md:rounded-xl text-sm md:text-lg font-medium md:w-48 w-36">
                        <select
                          className="bg-[#D9D9D9] focus:outline-none w-full"
                          onChange={(e) =>
                            handleFilterChange("location", e.target.value)
                          }
                          value={selectedFilters.location}
                        >
                          <option value="" className="text-xsm md:text-sm">
                            Location
                          </option>
                          {locations.map((location, index) => (
                            <option
                              key={index}
                              value={location}
                              className="text-xs md:text-xs"
                            >
                              {location}
                            </option>
                          ))}
                        </select>
                      </div>
                    </button>

                    {/* Company Filter */}
                    <button className="flex items-center bg-gray-200 text-[#393285] sm:px-4  px-2 py-2 rounded-lg mr-2 mb-2">
                      {/* <RiArrowDownSLine /> */}
                      <div className="bg-[#D9D9D9] md:ml-2  px-1 py-1 md:py-[5px] rounded-lg md:rounded-xl text-sm md:text-lg font-medium md:w-52 w-36">
                        <select
                          className="bg-[#D9D9D9] focus:outline-none w-full"
                          onChange={(e) =>
                            handleFilterChange("company", e.target.value)
                          }
                          value={selectedFilters.company}
                        >
                          <option value="" className="text-sm">
                            All Companies
                          </option>
                          {companies.map((company, index) => (
                            <option
                              key={index}
                              value={company}
                              className="text-sm"
                            >
                              {company}
                            </option>
                          ))}
                        </select>
                      </div>
                    </button>

                    <button className="flex items-center bg-gray-200 text-[#393285] sm:px-4  px-2 py-2 rounded-lg mr-2 mb-2">
                      {/* <RiArrowDownSLine /> */}
                      <div className="bg-[#D9D9D9] md:ml-2  px-1 py-1 md:py-[5px] rounded-lg md:rounded-xl text-sm md:text-lg font-medium md:w-52 w-36">
                        <select
                          className="bg-[#D9D9D9] focus:outline-none w-full"
                          onChange={(e) =>
                            handleFilterChange(
                              "educationQualification",
                              e.target.value
                            )
                          }
                          value={selectedFilters.educationQualification}
                        >
                          <option value="" className="text-sm">
                            Education
                          </option>
                          {educations.map((item, index) => (
                            <option
                              key={index}
                              value={item.value}
                              className="text-sm text-black"
                            >
                              {item.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </button>
                  </div>

                  <div className="  sm:flex hidden   gap-x-4 items-start justify-center gap-y-2 xxl:pr-12 xl:pr-8 pt-4 ">
                    <h1 className="text-base font-bold text-blue-200 text-center update">
                      For daily job updates, follow us on
                    </h1>
                    <div className="flex gap-x-2 justify-center items-center">
                      <a
                        href="https://chat.whatsapp.com/IyKRqBudFmg7ViOqQrJ9gr"
                        target="_blank"
                        rel="noopener noreferrer"
                        className=" w-7 h-7 rounded-full transition-all duration-300 hover:scale-110 cursor-pointer"
                      >
                        <LazyLoadImage
                          src="/whatsapp-1.png"
                          alt="whatsapp"
                          effect="blur"
                          className="w-full h-full object-cover rounded-full"
                        />
                      </a>

                      <a
                        href="https://chat.whatsapp.com/IyKRqBudFmg7ViOqQrJ9gr"
                        target="_blank"
                        rel="noopener noreferrer"
                        className=" w-7 h-7 rounded-full transition-all duration-300 hover:scale-110 cursor-pointer"
                      >
                        <LazyLoadImage
                          src="/teleg.png"
                          alt="whatsapp"
                          effect="blur"
                          className="w-full h-full object-cover rounded-full"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="container mx-auto w-[100%]">
              <div className="grid grid-cols-1 sm:grid-cols-2 xmd:grid-cols-3 justify-items-center  sm:gap-x-3 gap-x-2 gap-y-3  text-sm md:text-base xmd:text-lg ">
                {filteredJobs
                  .filter((job) => job.status === "accepted")
                  .map((job) => (
                    <div
                      key={job.id}
                      className="relative bg-blue-50 p-2 rounded-3xl shadow-md xl:w-96 xlg:w-80 lg:w-[300px] xmd:w-[280px] mmd:w-96 md:w-80 sm:w-[300px] xs:w-96 xsm:w-[93%] xxs:w-[95%]  w-[330px] mb-3"
                      onClick={() =>
                        navigate(`/job_details/${job.id}`, {
                          state: { alreadyApply: applied },
                        })
                      }
                    >
                      <FaAngleRight className="absolute right-2 text-primary-blue h-8 w-8 cursor-pointer" />
                      <div className="w-full flex items-center mb-3">
                        <div className="h-12 w-12 rounded-full">
                          <img
                            className="h-full w-full rounded-full object-cover"
                            src={job.profile ? job.profile : "/profile.jpg"}
                            alt="Company Logo"
                            name="profile"
                          />
                        </div>
                        <h3 className="pl-4 font-medium  w-3/4">
                          <span className="block  items-center  overflow-hidden text-ellipsis whitespace-nowrap text-base">
                            {job.jobTitle}
                          </span>

                          <span className="block  text-secondary-blue overflow-hidden text-ellipsis whitespace-nowrap text-base">
                            {job.companyName}
                          </span>
                        </h3>
                      </div>
                      <div className="w-full flex items-center mt-4 mb-1 ml-3 text-sm">
                        <div className="text-xl">
                          <IoLocationSharp />
                        </div>
                        <h3 className="pl-4 font-normal w-3/4">
                          <span className="block  overflow-hidden text-ellipsis whitespace-nowrap">
                            {job.jobLocation}
                          </span>
                        </h3>
                      </div>
                      <div className="w-full flex items-center mb-4 ml-3 text-sm ">
                        <div className="text-xl">
                          <FaMoneyBills />
                        </div>
                        <h3 className="pl-4 font-normal  w-3/4">
                          <span className="block  overflow-hidden text-ellipsis whitespace-nowrap ">
                            {job.salary}
                          </span>
                        </h3>
                      </div>
                      <div className="w-full flex items-center mb-1">
                        <button className="mt-1 bg-white text-[#393285] rounded-full px-3 py-1  overflow-hidden truncate text-base">
                          <p className="">click here for details</p>
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Frame10;
