import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { toast } from "react-toastify";
// import { useParams } from "react-router-dom";
// import Navemployee from "../Sections/Navbar-3&5/Navbar_employee";
import axios from "axios";
import Swal from "sweetalert2";
import HashLoader from "react-spinners/HashLoader";
import Navemployee from "../Sections/Navbar-3&5/Navbar_employee";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { FaRegCalendar } from "react-icons/fa6";




// import { toast } from "react-toastify";
function Section4() {
  const navigation = useNavigate();
  const token = localStorage.getItem("token");
  const [allowRender, setAllowRender] = useState(false);
  // const [loadin, setLoadin] = useState(true); // State to track loading

  const [profileImageURL, setProfileImageURL] = useState("/profile.jpg"); // Default fallback image
  const [errorPinMsg, setErrorPinMsg] = useState("")




  function handleSubmitButton() {
    Swal.fire({
      title: "Success",
      text: "Your Profile has been updated",

      icon: "success",
      confirmButtonText: "ok",
    }).then((result) => {
      if (result.isConfirmed) {
        navigation("/jobsearch");
      }
    });
  }
  let timerInterval;
  let loading = false;
  function handleProgress() {
    Swal.fire({
      title: "Profile has been updating .....",
      html: "",
      timerProgressBar: loading,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        // console.log("I was closed by the timer");
      }
    });
  }


  // const today = new Date();
  // const eighteenYearsAgo = new Date(
  //   today.getFullYear() - 18,
  //   today.getMonth(),
  //   today.getDate(),
  // )
  // console.log("Date of Birth  ", eighteenYearsAgo)
  // console.log("Date of Birth 2 ", eighteenYearsAgo.toISOString())
  // const formattedDate = eighteenYearsAgo.toISOString().split("T")[0];
  // console.log(formattedDate)

  const today = new Date()
  const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  const [selectedYear, setSelectedYear] = useState(eighteenYearsAgo.getFullYear()); // Default year set to 18 years ago
  const [displayedMonth, setDisplayedMonth] = useState(new Date(selectedYear, 0, 1)); // 👈 Control displayed month
  const startYear = today.getFullYear() - 18; // 18 years before today (2007 in 2025)
  const endYear = 1900; // The last year in the list
  
  const years = Array.from({ length: startYear - endYear + 1 }, (_, i) => startYear - i);


  const [formData, setFormData] = useState({
    // uid: token,
    name: "",
    profile: null,
    email: "",
    gender: "",
    mobileNumber: "",
    currentAddress: "",
    selectedState: "",
    selectedCity: "",
    dob: "",
    workexperience: "",
    pincode: "",
    education: "",
    pastjobexperience: "",
    // language: "English",
    status: "waiting",
  });



  //State for calendar visibility

  const [isCalendarOpen, setISCalendarOpen] = useState(false);




  const validateForm = () => {
    const {
      // profile,
      name,
      email,
      gender,
      mobileNumber,
      currentAddress,
      dob,
      selectedState,
      selectedCity,
      workexperience,
      pincode,
      education,
    } = formData;

    // if (!profile) return "Profile is required";
    if (!name) return "Name is required";
    if (!email || !/\S+@\S+/.test(email)) return "Valid email is required";
    if (!gender) return "Gender is required";
    if (!mobileNumber || !/^\d{10}$/.test(mobileNumber))
      return "Valid 10-digit number is ";
    if (!currentAddress) return "Address is required";
    if (!dob) return "Date of Birth is required";
    if (!selectedState) return "State is required";
    if (!selectedCity) return "City is required";
    if (!workexperience) return "Work Experience is required";
    if (!pincode || !/^\d{6}$/.test(pincode))
      return "Valid Pincode is required";
    if (!education) return "Education is required ";



    const birthDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();

    const monthDiff = today.getMonth() - birthDate.getMonth()
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age < 18) return "You must be at least 18 years old";

    return null; //No errors
  };








  const handleChange = (e) => {
    const { name, value } = e.target;



    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "pincode" && value.length === 6) {
      fetchLocationByPincode(value)
    }


  };


  const fetchLocationByPincode = async (pin) => {

    setErrorPinMsg("");
    setFormData((prev) => ({ ...prev, selectedState: "", selectedCity: "" }));

    try {

      const response = await fetch(`https://api.postalpincode.in/pincode/${pin}`);
      const data = await response.json();


      if (data[0].Status === "Success") {
        setFormData((prev) => ({
          ...prev,
          selectedState: data[0].PostOffice[0].State,
          selectedCity: data[0].PostOffice[0].District
        }))
      } else {
        setErrorPinMsg("Invalid Pincode! Please enter a valid one.")
      }

    } catch (err) {
      console.log("Error in Pincode api: ", err);
    }

  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    // Allowed image types
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];


    if (!(allowedTypes.includes(file.type))) {
      toast.warn("Please upload an image in JPEG, JPG and PNG format");
      return;
    }

    if (file.size > 1048576) {
      toast.warn("Image size should be less than 1MB");;
      return;
    }
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImageURL(imageUrl);
      setFormData({
        ...formData,
        [event.target.name]: event.target.files[0], // Only one file
      });
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        await axios.post(
          `${process.env.REACT_APP_PORT}/api/updateprofiledata`,
          { type: process.env.REACT_APP_TYPE_EMPLOYER },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setAllowRender(true);
      } catch (error) {
        // console.error("Invalide Credentials", error);
        if (!toast.isActive("invalid-credentials-toast")) {
          toast.error("Invalid Credentials", {
            toastId: "invalid-credentials-toast",
          });
        }
        // localStorage.clear();
        navigation("/");
      }
    }
    fetchData();
  }, [token, navigation]);

  if (!allowRender) {
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();

    if (errors) {
      toast.warn(errors);
      return;
    }
    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null) {
        formDataToSend.append(key, value);
      }
    });
    formDataToSend.append("type", process.env.REACT_APP_TYPE_EMPLOYER);
    loading = true;
    handleProgress();
    try {
      // console.log("guys");
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/api/upload`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Uncomment if authorization is needed
          },
        }
      );
      // console.log(response.message);
      // console.log("gymson");
      loading = false;
      handleSubmitButton();
      if (response.data.profileUrl) {
        setProfileImageURL(response.data.profileUrl);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Jammu and Kashmir",
    "Ladakh",
    "Lakshadweep",
    "Puducherry",
  ];

  const experienceLevel = [
    {
      id: 1,
      title: "Fresher",
      value: "fresher",
    },
    {
      id: 2,
      title: "Experience (0 - 1 yr)",
      value: "0-1yr",
    },
    {
      id: 3,
      title: "Experience (1 - 2 yr)",
      value: "1-2yr",
    },
    {
      id: 4,
      title: "Experience (more than 2 yr)",
      value: "moreThan2yr",
    },
  ];

  return (
    <>
      <div className="font-inter h-full w-full  ">
        {loading ? (
          <HashLoader
            color="#34a5f3"
            size={60} // Adjust the size as needed
            cssOverride={{
              position: "absolute", // Ensure the loader is positioned relative to the viewport
              top: "50%", // Center vertically
              left: "50%", // Center horizontally
              transform: "translate(-50%, -50%)", // Adjust to truly center the loader
            }}
            loading={true}
          />
        ) : (
          <div className="h-full w-full ">
            <Navemployee />
            <div className="flex justify-center mt-10  px-2">
              <h2 className="text-2xl smd:text-3xl md:text-4xl font-bold text-primary-blue sm:ml-8 text-center">
                Fill Your Details To Check New Openings
              </h2>
            </div>

            <form
              onSubmit={handleSubmit}
              className=" flex justify-center items-center "
            >
              <div className=" w-full flex flex-col smd:flex-row items-center justify-between mt-8 gap-1  ">
                <div className="flex items-center justify-center smd:justify-end smd:w-2/6  smd:self-baseline  ">
                  <div className="h-20 w-20 smd:mr-8 smd:mt-4  ">
                    <img
                      className="h-full w-full rounded-full object-cover cursor-pointer border  border-gray "
                      src={profileImageURL}
                      alt="Company Logo"
                      name="profile"
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      } // Trigger input click on image click
                    />
                    <input
                      id="fileInput"
                      type="file"
                      name="profile"
                      accept="image/*"
                      className="hidden"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>

                <div className=" realtive w-full smd:w-4/6 mx-auto  ">
                  <div className="flex flex-col smd:flex-row sm:justify-start smd:items-center gap-3 mb-2 flex-wrap justify-center items-center mx-auto mt-3">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Your Full name*"
                      className="placeholder-black  bg-blue-10 placeholder:text-ssm px-2 text-sm sm:text-base  text-center text-black rounded-full py-2 w-[75%] smd:w-[25%] "
                    />
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email ID*"
                      className="placeholder-black  bg-blue-10 placeholder:text-ssm px-2 text-sm smd:text-base text-center text-black rounded-full py-2 w-[75%] smd:w-[25%] "
                    />

                    <select
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                      className="placeholder-black  bg-blue-10 placeholder:text-ssm px-2 text-sm smd:text-base text-center text-black  rounded-full py-2 w-[75%] smd:w-[23%] "
                    >
                      <option value="" disabled>
                        Select Gender*
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="flex flex-col smd:flex-row smd:justify-start smd:items-center gap-3 mb-2 justify-center items-center smd:mt-3">
                    <input
                      type="text"
                      name="mobileNumber"
                      value={formData.mobileNumber}
                      onChange={handleChange}
                      placeholder="Mobile Number *"
                      className="placeholder-black  bg-blue-10 placeholder:text-ssm px-2 text-sm smd:text-base text-center text-black rounded-full py-2 w-[75%] smd:w-[25%]  "
                      minLength="10"
                      maxLength="10"
                      pattern="\d{10}"
                    />
                    <input
                      type="text"
                      name="currentAddress"
                      value={formData.currentAddress}
                      onChange={handleChange}
                      placeholder="Current Address *"
                      className="placeholder-black  bg-blue-10 placeholder:text-sm px-2 text-sm smd:text-base text-center text-black  rounded-full py-2 w-[75%] smd:w-[48%] "
                    />
                  </div>
                  <div className="flex flex-col smd:flex-row smd:justify-start smd:items-start gap-3 mb-2 justify-center items-center   smd:mt-3 ">
                    <div className="w-[75%] smd:w-[33%] flex justify-between items-center gap-x-2 relative">
                      <label htmlFor="dob" className="smd:hidden flex xs:w-[30%] w-[40%] xs:text-sm text-[13px] ">Date of Birth: </label>
                      {/* <input
                        id="dob"
                        type="date"
                        name="dob"
                        value={formData.dob}
                        // max={formattedDate..;.;;.;}
                        onChange={handleChange}
                        placeholder="Date of Birth*"
                        // max={formattedDate}
                        className="placeholder-black  bg-blue-10 placeholder:text-sm px-2 text-sm sm:text-base text-center text-black  rounded-full py-2 xs:w-[80%] w-[58%]  smd:w-full  "
                      /> */}

                      <input
                        type="text"
                        readOnly
                        value={formData.dob ? format(new Date(formData.dob), "yyyy-MM-dd") : ""}
                        onClick={() => setISCalendarOpen(!isCalendarOpen)}
                        className="placeholder-black  bg-blue-10 placeholder:text-sm px-2 text-sm sm:text-base text-center text-black  rounded-full py-2 xs:w-[80%] w-[65%]  smd:w-full cursor-pointer  "
                        placeholder="Date of Birth "
                      />

                      <div className="absolute right-4 cursor-pointer"   onClick={() => setISCalendarOpen(!isCalendarOpen)}>
                        <FaRegCalendar/>
                      </div>

                      {
                        isCalendarOpen && (
                          <div className="absolute z-50 py-1 border border-black-10 px-1 text-sm bg-white top-12">
                            {/* Year Dropdown for Quick Selection */}
                            <select
                              value={selectedYear}
                              onChange={(e) => {
                                const year = Number(e.target.value);
                                setSelectedYear(year);
                                setDisplayedMonth(new Date(year, 0, 1)); // 👈 Update the displayed month dynamically
                              }}
                              className="w-full mb-2 p-2 border-2 rounded-md  "
                            >
                              {years.map((year) => (


                                <option key={year} value={year} className="border h-[200px]" >
                                  {year}
                                </option>

                              ))}
                            </select>

                            <DayPicker
                              mode="single"
                              month={displayedMonth} // 👈 Controlled month updates dynamically
                              selected={formData.dob ? new Date(formData.dob) : undefined}
                              onMonthChange={setDisplayedMonth} // 👈 Allow user to navigate manually too
                              onSelect={(date) => {
                                if (date) {
                                  const formattedDate = format(date, "yyyy-MM-dd");
                                  setFormData((prev) => ({ ...prev, dob: formattedDate }));
                                  setISCalendarOpen(false);
                                }
                              }}
                              disabled={[
                                { from: eighteenYearsAgo, to: today }, // ❌ Disable under-18 dates
                                { from: today, to: new Date(today.getFullYear() + 100, today.getMonth(), today.getDate()) }, // ❌ Disable future dates
                              ]}
                              className="custom-calendar"
                            />
                          </div>
                        )
                      }

                    </div>


                    {/* <input
                        type="text"
                        name="selectedState"
                        value={formData.selectedState}
                        onChange={handleChange}
                        placeholder="State *"
                        className="placeholder-black  bg-blue-10 placeholder:text-ssm px-2 text-sm sm:text-base text-center text-black  rounded-full py-2 w-[36%] sm:w-[25%] "
                      /> */}

                    <div className="flex flex-col w-[75%] smd:w-[25%] ">
                      <input
                        type="text"
                        name="pincode"
                        value={formData.pincode}
                        onChange={handleChange}
                        placeholder="Pincode *"
                        className="placeholder-black  bg-blue-10 placeholder:text-ssm px-2  text-sm smd:text-base text-center text-black rounded-full py-2 w-full "
                      />
                      {
                        errorPinMsg && <span className="text-red-100 text-sm mt-2 text-center">{errorPinMsg}</span>
                      }

                    </div>


                    <select
                      name="selectedState"
                      value={formData.selectedState}
                      onChange={handleChange}
                      className="placeholder-black  bg-blue-10 placeholder:text-ssm px-2 text-sm smd:text-base text-center text-black  rounded-full py-2 w-[75%] smd:w-[23%] "
                    >
                      <option value="" disabled>
                        Select State*
                      </option>
                      {states.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>



                    {/* <input
                      type="text"
                      name="selectedCity"
                      value={formData.selectedCity}
                      onChange={handleChange}
                      placeholder="City *"
                      className="placeholder-black  bg-blue-10 placeholder:text-ssm px-2 text-sm sm:text-base text-center text-black  rounded-full py-2 w-[36%] sm:w-[25%]  "
                    /> */}
                  </div>
                  <div className="flex flex-col smd:flex-row smd:justify-start smd:items-start gap-3 mb-2 justify-center items-center  smd:mt-3">


                    {/* <select
                      name="workexperience"
                      value={formData.workexperience}
                      onChange={handleChange}
                      className="placeholder-black  bg-blue-10 placeholder:text-ssm px-2 text-sm sm:text-base text-center text-black  rounded-full py-2  w-[75%] sm:w-[23%] "
                    >
                      <option value="" disabled>
                        work experience
                      </option>
                      {experienceLevel.map((level, index) => (
                        <option key={index} value={level.value}>
                          {level.title}
                        </option>
                      ))}
                    </select> */}






                    {/* <input
                        type="text"
                        name="pincode"
                        value={formData.pincode}
                        onChange={handleChange}
                        placeholder="Pincode *"
                        className="placeholder-black  bg-blue-10 placeholder:text-ssm px-2  text-sm sm:text-base text-center text-black rounded-full py-2 w-[36%] sm:w-[25%] "
                      /> */}

                    <input
                      type="text"
                      name="selectedCity"
                      value={formData.selectedCity}
                      onChange={handleChange}
                      placeholder="City *"
                      className="placeholder-black  bg-blue-10 placeholder:text-ssm px-2 text-sm smd:text-base text-center text-black  rounded-full py-2 w-[75%] smd:w-[25%]  "
                    />


                    <select
                      name="workexperience"
                      value={formData.workexperience}
                      onChange={handleChange}
                      className="placeholder-black  bg-blue-10 placeholder:text-ssm xl:px-2 text-sm smd:text-base text-center text-black  rounded-full py-2  w-[75%] smd:w-max "
                    >
                      <option value="" disabled>
                        work experience
                      </option>
                      {experienceLevel.map((level, index) => (
                        <option key={index} value={level.value}>
                          {level.title}
                        </option>
                      ))}
                    </select>

                    <input
                      type="text"
                      name="education"
                      value={formData.education}
                      onChange={handleChange}
                      placeholder="Education *"
                      className="placeholder-black  bg-blue-10 placeholder:text-ssm px-2 text-sm smd:text-base text-center text-black rounded-full py-2 w-[75%] smd:w-[25%] "
                    />
                  </div>





                  <div className="flex flex-col smd:flex-row smd:justify-start smd:items-start gap-3 mb-4 justify-center items-center mt-3">
                    <button
                      type="submit"
                      className=" bg-blue-500 text-white bg-primary-blue smd:ml-40 mx-auto smd:mt-5 rounded-full py-[6px] px-10 text-base hover:scale-110 transition-all duration-300 "
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
}

export default Section4;
