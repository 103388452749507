import React from "react";
import { useState } from "react";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./Navbar";
import Footer from "./Footer2";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    query: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/submitForm`,
        formData
      );
      toast.success(response.data.message);
    } catch (err) {
      console.error("Error while submitting form: ", err);
      toast.error("Error while submitting form. Please try again later.");
    }
  };

  return (
    <>
      <Navbar />
      <section className="py-10 font-inter">
        {/* ------- container --------- */}

        <div className="xl:w-10/12 w-11/12 mx-auto  flex xmd:flex-row flex-col gap-y-16 justify-between gap-x-8 py-6 sm:px-6  xmd:px-8 sm:border-2 border-blue-200 rounded-[30px]">
          {/* -------- left part ------ */}

          <div className="xmd:w-[45%] sm:w-[80%] mx-auto  flex flex-col xl:gap-y-6  xs:gap-y-8 ">
            {/* ------ heading ---- */}

            <h1 className="  text-4xl  font-bold text-blue-200 tracking-wider">
              Contact Us!!
            </h1>

            {/* ------- image ------- */}

            <div className=" xl:h-[400px] xmd:h-[500px] md:h-[450px] xs:h-[400px] h-[360px]">
              <LazyLoadImage
                src="./contact.png"
                alt="contact"
                className="w-full h-full object-cover"
              />
            </div>

            {/* -------- description ----- */}

            <div className="text-sm xmd:text-base font-normal text-light-black-200 mt-4 xlg:w-[90%] flex flex-col gap-y-4">
              <p>
                Give us a call (08069277007) or send us an email
                (info@hirers.co.in) We're happy to assist you with any questions
                you may have.
              </p>

              <p>Registered Address: 7/302 Laxmi Nagar, New Delhi - 110092</p>
            </div>
          </div>

          {/* ------- right part -------- */}

          <div className="xl:w-[45%] xmd:w-[49%] sm:w-[80%] w-full mx-auto  bg-light-purple-100 rounded-2xl flex flex-col xs:gap-y-8 gap-y-8 py-6 xmd:px-6 xs:px-12 px-6">
            <h2 className=" text-2xl text-black font-semibold text-center">
              Request a Call Back
            </h2>

            <form onSubmit={handleSubmit} className="flex flex-col gap-y-4">
              {/* ------ name ---- */}

              <input
                type="text"
                placeholder="Your Name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="px-6 xs:py-3 py-2 bg-white-100 text-base xs:text-lg  text-black rounded-3xl placeholder-black outline-none focus:outline-blue-100"
                required
              />

              {/* ------- mobile number --- */}

              <input
                type="tel"
                placeholder="Mobile"
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
                className="px-6 xs:py-3 py-2 bg-white-100 text-base xs:text-lg  text-black rounded-3xl placeholder-black outline-none focus:outline-blue-100"
                required
              />

              {/* --------  email -------- */}

              <input
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="px-6 xs:py-3 py-2 bg-white-100  text-base xs:text-lg  text-black rounded-3xl placeholder-black outline-none focus:outline-blue-100"
                required
              />

              {/* -------query -------- */}

              <textarea
                name="query"
                id="query"
                placeholder="Write your queries here..."
                value={formData.query}
                onChange={handleInputChange}
                className="h-[200px] px-6 xs:py-3 py-2 bg-white-100  text-base xs:text-lg  text-black rounded-3xl placeholder-black outline-none focus:outline-blue-100"
                required
              ></textarea>

              {/* ------ submit btn ----- */}

              <div>
                <button
                  type="submit"
                  className="py-2 px-6 rounded-xl text-white bg-blue-300  text-base xs:text-lg font-medium transition-all duration-300
                            hover:scale-105
                            hover:bg-blue-100"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ContactUs;
