import React from "react";
import { useNavigate } from "react-router-dom";
import Hirers from "./Why_Hirers_New";
import Cards from "./Cards_New";
import Footer from "./Footer2";
import Navbar from "./Navbar";
import Navbar1 from "../Sections/Navbar-3&5/navbar1";
import Navemployee from "../Sections/Navbar-3&5/Navbar_employee";
import HorizontalScroll from "./companys_scroll";
import Frame3 from "../Frames/Frame3";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import axios from "axios";
import HashLoader from "react-spinners/HashLoader";

const Cards2 = () => {
  const [showHeading, setShowHeading] = useState(true);
  const navigate = useNavigate();
  const [typeOfUser, setTypeOfUser] = useState("all");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const intervalOfHead = setInterval(() => {
      setShowHeading((prev) => !prev);
    }, 5000);

    return () => clearInterval(intervalOfHead);
  }, []);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const tokenCheck = async () => {
      if (!token) {
        setLoading(false);
        setTypeOfUser("all");
        return;
      } // Prevent API call if token is missing

      try {
        const response = await axios.post(
          ` ${process.env.REACT_APP_PORT}/api/check_auth`,
          {}, // Empty body since we only need headers
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const result = response.data;
        if (result.success) {
          if (result.data === "users") {
            setTypeOfUser("jobSeeker");
          } else if (result.data === "employee") {
            setTypeOfUser("employee");
          } else {
            setTypeOfUser("all");
          }
        }
      } catch (error) {
        console.error(
          "Error checking authentication:",
          error.response?.data || error.message
        );
        localStorage.clear();
      } finally {
        setLoading(false);
      }
    };

    tokenCheck();
  }, [token]);

  const socialIcon = [
    {
      id: 1,
      title: "whatsapp",
      icon: "/whatsapp-1.png",
      path: "https://chat.whatsapp.com/IyKRqBudFmg7ViOqQrJ9gr",
    },
    {
      id: 2,
      title: "telegram",
      icon: "/teleg.png",
      path: "https://t.me/job_at_hirers",
    },
    {
      id: 3,
      title: "facebook",
      icon: "/facebook-1.png",
      path: "https://www.facebook.com/hirers.co.in/",
    },
    {
      id: 4,
      title: "instagram",
      icon: "/instagram-1.png",
      path: "https://www.instagram.com/hirers.co.in/",
    },
    {
      id: 5,
      title: "linkeIn",
      icon: "/linkedin-1.png",
      path: "https://www.linkedin.com/company/hirers-co-in/?viewAsMember=true",
    },
  ];

  //Post job - /employee_login
  //Serach job = /candidate_login

  // console.log("Hello , this is dessktop 1")

  return (
    <>
      {loading ? (
        <HashLoader
          color="#34a5f3"
          size={60} // Adjust the size as needed
          cssOverride={{
            position: "absolute", // Ensure the loader is positioned relative to the viewport
            top: "50%", // Center vertically
            left: "50%", // Center horizontally
            transform: "translate(-50%, -50%)", // Adjust to truly center the loader
          }}
          loading={true}
        />
      ) : (
        <div className="mt-30" id="home">
          {/* <div className="top-0 left-0 right-0 z-50 bg-yellow-100 p-3 overflow-hidden whitespace-nowrap">
            <div className="animate-[marquee_20s_linear_infinite] inline-block">
              <span className="text-lg font-medium">
                Website under maintenance - We're working to improve your
                experience! Please bear with us . 
              </span>
            </div>
          </div> */}
          {typeOfUser === "jobSeeker" ? (
            <Navemployee className="sm:w-full" />
          ) : typeOfUser === "employee" ? (
            <Navbar1 className="sm:w-full" />
          ) : (
            <Navbar className="sm:w-full" />
          )}

          {/* ------ new hero section ----- */}

          <section className="min-h-[460px] pt-8 sm:pb-20 pb-8 bg-center bg-cover bg-no-repeat hero-section relative overflow-x-hidden">
            {/* ---------- container --------- */}

            <div className="lg:w-10/12 w-11/12 mx-auto  h-full flex flex-col  gap-y-16">
              {/* -------- upper part ------- */}

              <div className="flex flex-col justify-between sm:h-[190px] xxs:h-[170px] h-[190px]  ">
                {/* -------- mobile view social icons --------- */}

                <AnimatePresence mode="wait">
                  {showHeading ? (
                    <motion.div
                      key="heading1"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.5 }}
                      className="flex flex-col  gap-y-3 xs:px-0 px-6 shadow-part  "
                    >
                      <div className="flex flex-col xmd:gap-y-2 gap-y-1 items-center text-center  lg:text-4xl xmd:text-3xl xs:text-2xl text-xl font-bold text-blue-200 tracking-wide drop-shadow-lg">
                        <h1 className="drop-shadow-lg sm:drop-shadow-none">
                          Find, Apply, and Secure
                        </h1>

                        <h1 className="drop-shadow-lg sm:drop-shadow-none">
                          Your Dream Job with Hirers in Just 2 Days!
                        </h1>
                      </div>

                      <p
                        style={{
                          color: "black",
                          textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
                          transition: "color 0.5s ease-in-out, transform 0.5s",
                        }}
                        className=" lg:text-2xl md:text-xl text-lg sm:flex hidden justify-center text-center drop-shadow-2xl font-abeezee"
                      >
                        Experience the accelerated journey to your dream career
                        with us.
                      </p>
                    </motion.div>
                  ) : (
                    <motion.div
                      key="heading-2"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.5 }}
                      className="flex flex-col gap-y-3 xs:px-0 px-2 shadow-part "
                    >
                      <div className="flex flex-col xmd:gap-y-2 gap-y-1 items-center text-center  lg:text-4xl xmd:text-3xl xs:text-2xl text-xl font-bold text-blue-200 tracking-wide drop-shadow-lg ">
                        <h1 className="drop-shadow-lg sm:drop-shadow-none">
                          Elevate your team with Hirers!
                        </h1>

                        <h1 className="drop-shadow-lg sm:drop-shadow-none ">
                          Post, find and empower your organization.
                        </h1>
                      </div>

                      <p
                        style={{
                          color: "black",
                          textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
                          transition: "color 0.5s ease-in-out, transform 0.5s",
                        }}
                        className=" lg:text-2xl md:text-xl text-lg sm:flex hidden justify-center text-center drop-shadow-2xl font-abeezee"
                      >
                        Join the journey with Hirers towards building a
                        stronger, more accomplished team.
                      </p>
                    </motion.div>
                  )}
                </AnimatePresence>

                {/* -------- mobile view social icons --------- */}

                <div className="  flex flex-col xmd:hidden  gap-x-4 justify-center gap-y-2">
                  <h1 className="text-base font-semibold text-blue-200 text-center update">
                    Dont miss any opportunity, follow us on
                  </h1>
                  <div className="flex gap-x-4 justify-center">
                    {socialIcon.map((item) => (
                      <a
                        href={item.path}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={item.id}
                        className="xs:w-9 xs:h-9 w-8 h-8 rounded-full transition-all duration-300 hover:scale-110 cursor-pointer"
                      >
                        <LazyLoadImage
                          src={item.icon}
                          alt={item.title}
                          effect="blur"
                          className="w-full h-full object-cover rounded-full"
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </div>

              {/* --------- bottom part ---------- */}

              <div className="flex xmd:flex-row flex-col-reverse justify-center items-center xxl:gap-x-36 sm:gap-y-16 gap-y-10 lg:gap-x-32 gap-x-16 ">
                {/* --------- employers ------ */}

                <div className="flex flex-col sm:gap-y-8 gap-y-4">
                  <div className=" md:w-[330px] sm:w-[350px] w-[280px]  sm:h-[280px] h-[260px] bg-light-purple-100 rounded-2xl  relative">
                    <div className="xxl:pl-40 lg:pl-32 pl-28 pr-3 py-4 flex flex-col sm:gap-y-4 gap-y-2 ">
                      <h2 className=" xmd:text-xl text-lg font-bold text-blue-200">
                        For Employers
                      </h2>
                      <p className=" xmd:text-xl sm:text-lg text-base leading-7 font-semibold text-light-black">
                        Hire from a large pool of filtered candidates!!
                      </p>
                    </div>

                    {/* -------- employer img ------ */}
                    <div className="absolute sm:left-[-150px] left-[-80px]  sm:top-[-50px] top-[-10px] ">
                      <LazyLoadImage
                        src="/employer.png"
                        alt="employers"
                        effect="blur"
                        className="xxl:w-[320px] sm:w-[300px] w-[220px]"
                      />
                    </div>

                    {/* -------- arrow img ----- */}

                    <div className="absolute xmd:right-[30px] right-[40px] sm:top-[180px] top-[160px] xmd:rotate-[220deg] rotate-[240deg] ">
                      <LazyLoadImage
                        src="/arrow-acw.png"
                        alt="arrow"
                        effect="blur"
                        className=" w-[60px]"
                      />
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <button
                      onClick={() => navigate("/employee_login")}
                      className="py-2 lg:px-8 px-6 bg-blue-200 text-white rounded-2xl  sm:text-xl transition-all duration-300 hover:scale-105 text-lg"
                    >
                      Post Job
                    </button>
                  </div>
                </div>

                {/* -------- jobseekers ---------- */}

                <div className="flex flex-col sm:gap-y-8 gap-y-4">
                  <div className=" md:w-[330px] sm:w-[350px] w-[280px] sm:h-[280px] h-[260px] bg-light-purple-100 rounded-2xl  relative">
                    <div className="xxl:pr-36 sm:pr-32 pr-28 pl-4 py-4 flex flex-col sm:gap-y-4 gap-y-2 ">
                      <h2 className="sm:text-xl text-lg font-bold text-blue-200">
                        For JobSeekers
                      </h2>
                      <p className=" xmd:text-xl sm:text-lg  text-base leading-5 font-semibold text-light-black">
                        Get 100% verified Jobs at no <br></br>
                        cost!!
                      </p>
                    </div>

                    {/* -------- jobseeker -------- */}

                    <div className="absolute  sm:right-[-120px] right-[-60px] sm:top-[-50px] top-[-10px] ">
                      <LazyLoadImage
                        src="/jobseekers.png"
                        alt="jobseeker"
                        effect="blur"
                        className="xxl:w-[330px] sm:w-[300px] w-[220px]"
                      />
                    </div>

                    {/* -------- arrow img ----- */}

                    <div className="absolute left-[30px] sm:top-[180px] top-[160px] xmd:rotate-[130deg] rotate-[100deg]">
                      <LazyLoadImage
                        src="/arrow-cw.png"
                        alt="arrow"
                        effect="blur"
                        className=" w-[60px]"
                      />
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <button
                      onClick={() => navigate("/candidate_login")}
                      className="py-2 lg:px-8 px-6 bg-blue-200 text-white rounded-2xl sm:text-xl text-lg transition-all duration-300 hover:scale-105"
                    >
                      Search Job
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* -------- side social icons -------- */}

            <div className="w-max  xmd:flex hidden flex-col  gap-y-4 absolute xlg:top-[30%] lg:top-[20%] top-[15%] xxl:right-16 xl:right-10 xlg:right-8 right-6">
              {socialIcon.map((item) => (
                <a
                  href={item.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={item.id}
                  className=" w-10 transition-all duration-300 hover:scale-110 cursor-pointer"
                >
                  <LazyLoadImage
                    src={item.icon}
                    alt={item.title}
                    effect="blur"
                    className="w-full h-full object-cover"
                  />
                </a>
              ))}
            </div>
          </section>

          <Frame3 />
          <Cards />
          <Hirers />
          <HorizontalScroll data="Our Customers" />
          {/* <Scroll/> */}
          <Footer />
        </div>
      )}
    </>
  );
};

export default Cards2;
