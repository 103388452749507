import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const messaging = getMessaging(app);

// Request Notification Permission
const requestPermission = async () => {
  try {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const notify_token = await getToken(messaging, {
        vapidKey:
          "BDaxupM6ansDWjTwp-ZrmoWHLriXtrFTrn7Ng3CPHLot7v5_b4n__wsq8A8saOUO0B9Q-pBtJqcHWnQr-64pNVI",
      });
      if (notify_token) {
        console.log(notify_token);
        return notify_token;
      } else {
        console.log("No token found.");
      }
    } else {
      console.log("Notification permission denied.");
    }
  } catch (error) {
    console.error("getting permission:", error);
  }
};

const onMessageListener = () =>
  new Promise((resolve, reject) => {
    try {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    } catch (error) {
      reject(error);
    }
  });

const googleProvider = new GoogleAuthProvider();

export {
  auth,
  messaging,
  requestPermission,
  googleProvider,
  onMessageListener,
};
