import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navbar1 from "../Sections/Navbar-3&5/navbar1";
import HashLoader from "react-spinners/HashLoader";
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";
import { requestPermission } from "../firebase";

const Frame18 = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("Your Name");
  const [designation, setDesignation] = useState("Designation");
  const [jobs, setJobs] = useState([]);
  const [activeJobsCount, setActiveJobsCount] = useState(0);
  const [sortField, setSortField] = useState("PostedDate");
  const [sortOrder, setSortOrder] = useState("asc");

  const token = localStorage.getItem("token");

  const handleSubmitButton = () => navigate("/postjob");
  const handleEditProfile = () => navigate("/employee_profile");

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const handleWaiting = () => {
    Swal.fire(
      {
        title: "Under Progress",
        text: "Admin is verifying your details on progress",
        icon: "info",
      },
      [jobs, sortField, sortOrder]
    );
  };

  const handleCheckResponses = (documentId, jobTitle) => {
    navigate(`/responses/${documentId}`, { state: jobTitle });
  };

  const sortJobs = useCallback(() => {
    const sortedJobs = [...jobs].sort((a, b) => {
      const dateA = a[sortField] ? new Date(a[sortField]) : null;
      const dateB = b[sortField] ? new Date(b[sortField]) : null;

      if (dateA && dateB) {
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      } else if (dateA) {
        return -1;
      } else if (dateB) {
        return 1;
      }
      return 0;
    });

    setJobs(sortedJobs);
  }, [jobs, sortField, sortOrder]);

  const fetchJobs = useCallback(async () => {
    try {
      const profileResponse = await axios.post(
        `${process.env.REACT_APP_PORT}/api/updateprofiledata`,
        { type: process.env.REACT_APP_TYPE_EMPLOYEE },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!profileResponse.data.name) {
        navigate("/employee_profile");
        return;
      }

      setName(profileResponse.data.name);
      setDesignation(profileResponse.data.designation);

      const jobsResponse = await axios.post(
        `${process.env.REACT_APP_PORT}/api/getJobById`,
        { type: process.env.REACT_APP_TYPE_EMPLOYEE },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!jobsResponse.data.message) {
        const activeJobs = jobsResponse.data.filter(
          (job) => job.status === "accepted"
        );
        setActiveJobsCount(activeJobs.length);
        setJobs(jobsResponse.data);
      }
    } catch (error) {
      if (!toast.isActive("invalid-credentials-toast")) {
        toast.error("Session Timeout Please Login Again", {
          toastId: "invalid-credentials-toast",
        });
        localStorage.clear();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchJobs();
  }, [token, navigate, fetchJobs]);

  useEffect(() => {
    requestPermission().then(async (notify_token) => {
      if (notify_token) {
        try {
          await axios.post(
            `${process.env.REACT_APP_PORT}/api/subscribe`,
            {
              type: process.env.REACT_APP_TYPE_EMPLOYEE,
              token: notify_token,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } catch (error) {
          console.error("Error subscribing to notifications:", error);
        }
      }
    });
  }, [token]);

  useEffect(() => {
    sortJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortField, sortOrder]);

  const handleCheckResponse_Data = (documentId, jobTitle) => {
    navigate(`/postedjobinfo/`, { state: { jobId: documentId, jobTitle } });
  };

  return (
    <>
      <div className="bg-gray-50 min-h-screen">
        {loading ? (
          <HashLoader
            color="#34a5f3"
            size={60}
            cssOverride={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        ) : (
          <div>
            <Navbar1 />

            <section className="w-full h-full b py-16 font-inter">
              <div className="xlg:w-11/12 w-[93%] mx-auto flex flex-col  md:gap-y-20 sm:gap-y-16 xs:gap-y-10 gap-y-8">
                {/* -------- Profile  Section -------- */}

                <div className="flex justify-between flex-col-reverse xmd:flex-row  gap-x-6 xs:gap-y-8 gap-y-4">
                  {/* -------- left part ----- */}

                  <div className=" w-full xmd:w-[30%]  flex xl:gap-x-24 xmd:gap-x-16 py-6 xmd:justify-center  justify-between smd:text-xl xs:text-lg text-base">
                    {/* -------- Jobs Posted ------ */}

                    <div className="flex xmd:flex-col flex-row-reverse items-center gap-y-3 gap-x-3 ">
                      <span className=" xl:py-2 py-1 xl:px-7 xs:px-5 px-3 rounded-xl bg-light-purple-100 xmd:text-2xl xs:text-xl text-lg">
                        {jobs.length}
                      </span>
                      <p className="flex xmd:flex-col flex-row gap-x-2 items-center font-medium text-center">
                        <span>Jobs</span>
                        <span>Posted</span>
                      </p>
                    </div>

                    {/* -------- Jobs Active------ */}

                    <div className="flex xmd:flex-col flex-row-reverse items-center gap-y-3 gap-x-3 ">
                      <span className="  xl:py-2 py-1 xl:px-7 xs:px-5 px-3 rounded-xl bg-light-purple-100 xmd:text-2xl xs:text-xl text-lg">
                        {activeJobsCount}
                      </span>
                      <p className="flex xmd:flex-col flex-row gap-x-2 items-center font-medium text-center">
                        <span>Jobs</span>
                        <span>Active</span>
                      </p>
                    </div>
                  </div>

                  {/* ------- Manage Job -------- */}

                  <div
                    className="w-full xmd:w-[60%] bg-light-purple-100 rounded-2xl xmd:py-0 
                xsm:py-6 py-4 sm:px-8 xsm:px-3 px-2 flex  justify-between items-center  xmd:text-2xl xs:text-xl xsm:text-lg text-base "
                  >
                    <div className="flex flex-col  xs:gap-y-4 gap-y-2  ">
                      <h2 className="font-semibold">Manage Job Posting</h2>

                      <div className="flex flex-row sm:gap-x-12 xs:gap-x-8 gap-x-4 font-normal  sm:text-lg xsm:text-base text-sm font-abeezee">
                        <p>{designation}</p>
                        <p>{name}</p>
                      </div>
                    </div>

                    <button
                      onClick={handleSubmitButton}
                      className=" w-max sm:py-2 py-[6px] xmd:px-8 xs:px-4 px-2 bg-white  md:text-lg sm:text-base text-sm font-semibold rounded-2xl transition-all duration-300 hover:scale-105"
                    >
                      Post New Job
                    </button>
                  </div>
                </div>

                {/* --------- Job Section ------- */}

                <div className=" flex flex-col gap-y-6">
                  <div className="flex flex-row gap-x-8 ">
                    {/* ---------- sort by posted date and expiry date ----- */}

                    <label className=" md:text-xl sm:text-lg text-base flex items-center gap-x-3 font-normal font-abeezee">
                      Sort By:
                      <select
                        id="options"
                        value={sortField}
                        onChange={(e) => setSortField(e.target.value)}
                        className="bg-light-purple-100 py-2 px-3 rounded-lg  sm:text-lg text-sm"
                      >
                        <option value="PostedDate">Posted Date</option>
                        <option value="ExpiryDate">Expire Date</option>
                        {/* <option value="old">Old</option> */}
                      </select>
                      <button
                        className="bg-light-purple-100 py-2 px-3 ml-8 rounded-lg  sm:text-lg text-sm "
                        onClick={() =>
                          setSortOrder(sortOrder === "asc" ? "desc" : "asc")
                        }
                      >
                        {sortOrder === "asc"
                          ? "Ascending Order"
                          : "Descending Order"}
                      </button>
                    </label>
                  </div>

                  <div className="flex flex-col  gap-y-6  ">
                    {jobs && jobs.length > 0 ? (
                      jobs.map((job, index) => (
                        <div
                          key={index}
                          className="bg-light-purple-100 py-4 xxl:pl-6 xxl:pr-24 xs:px-6 px-4  rounded-[15px] flex flex-col justify-center gap-y-3 xmd:gap-y-2"
                        >
                          <h2
                            className="w-max md:text-xl text-lg uppercase text-blue-200 font-semibold cursor-pointer hover:scale-110 transition-all duration-200"
                            onClick={() => {
                              handleCheckResponse_Data(
                                job.documentId,
                                job.jobTitle
                              );
                            }}
                          >
                            {job.companyName}
                          </h2>

                          <div className="flex justify-between  ">
                            <div className="flex xmd:flex-row xmd:w-[60%] flex-col  xmd:justify-between font-abeezee xlg:text-lg  gap-x-3 xmd:text-base sm:text-lg xsm:text-base text-sm  gap-y-2 xmd:items-end">
                              <p>Posted on: {formatDate(job.PostedDate)}</p>

                              <p>Expire on: {formatDate(job.ExpiryDate)}</p>

                              <p>Job Status: {job.status || "Pending"}</p>
                            </div>

                            <div className="flex xxl:w-[30%] xmd:w-[36%] xmd:flex-row flex-col  gap-y-8 md:gap-y-12 gap-x-16 ">
                              <div className="flex  flex-col items-center gap-y-2 ">
                                Status
                                <span className="bg-white py-1 px-4 xs:text-base text-sm rounded-2xl">
                                  {job.status || "Pending"}
                                </span>
                              </div>

                              <div className="flex items-end">
                                <button
                                  className={` h-max flex justify-center md:py-2 py-1 px-3 bg-white rounded-2xl transition-all duration-300 hover:scale-105 text-sm xs:text-base  ${
                                    job.status === "rejected"
                                      ? "cursor-not-allowed opacity-50"
                                      : "cursor-pointer hover:bg-gray-200 hover:shadow-xl transition-all duration-300"
                                  }`}
                                  onClick={() => {
                                    if (job.status === "accepted") {
                                      handleCheckResponses(
                                        job.documentId,
                                        job.jobTitle
                                      );
                                    } else if (job.status === "pause") {
                                      handleCheckResponses(
                                        job.documentId,
                                        job.jobTitle
                                      );
                                    } else if (job.status === "waiting") {
                                      handleWaiting();
                                    }
                                  }}
                                  disabled={job.status === "rejected"} // Disable button if status is rejected
                                >
                                  Check Response
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-center  flex items-center justify-center font-semibold text-blue-200 xs:text-2xl xsm:text-xl text-lg mt-8">
                        No jobs available!
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>

            <div className="bg-white rounded-lg p-6 mt-4 max-h-full hidden shadow-lg">
              {/* Profile Section */}
              <div className="flex flex-col md:flex-row justify-between items-center mb-5">
                <div className="flex flex-row justify-between items-center md:flex-row text-center md:text-left px-4 py-4 md:py-0 md:px-8 bg-[#DFDCF7] rounded-lg mt-6 md:mt-0 order-1 md:order-2 md:w-2/3 md:h-28">
                  <div>
                    <h2 className="text-lg md:text-3xl font-semibold">
                      Manage Job Posting
                    </h2>
                    <div className="flex flex-row space-x-2 md:space-x-8 md:flex-row mt-4">
                      <div className="text-xs font-semibold md:text-3xl">
                        {name}
                      </div>
                      <div className="text-xs font-semibold md:text-3xl">
                        {designation}
                      </div>
                    </div>
                  </div>
                  <button
                    className="text-xl md:text-3xl text-black hover:bg-blue-400 font-semibold m-4 md:mt-0 py-2 px-4 bg-white rounded-lg w-fit shadow hover:shadow-xl transition-all duration-300 transform hover:scale-105"
                    onClick={handleSubmitButton}
                  >
                    Post New Job
                  </button>
                </div>
                {/* Jobs Summary */}
                <div className="flex space-x-4 w-full md:w-auto justify-center md:justify-start order-2 md:order-1 mt-6 md:mt-0">
                  <div className="text-center flex md:block">
                    <div className="text-4xl flex items-center justify-center mx-auto md:ml-9 order-2 md:order-1 w-[87px] h-[63px] bg-[#DFDCF7] rounded-2xl">
                      {jobs.length}
                    </div>
                    <div className="text-black font-inter text-lg md:text-3xl  font-semibold order-1 md:order-2">
                      Jobs Posted
                    </div>
                  </div>
                  <div className="text-center pl-4 md:pl-24 text-xl md:text-3xl flex md:block">
                    <div className="flex items-center justify-center text-4xl mx-auto order-2 md:order-1 w-[87px] h-[63px] bg-[#DFDCF7] rounded-2xl">
                      {activeJobsCount}
                    </div>
                    <div className="text-black font-semibold text-left order-1 md:order-2">
                      Jobs Active
                    </div>
                  </div>
                </div>
              </div>
              {/* Sort and Jobs Display */}
              <div className="flex flex-row pt-7">
                <div className="flex flex-row mt-10">
                  <div className="text-xl md:text-2xl font-semibold md:text-left w-[80vw] mb-5">
                    Sort By:&nbsp;&nbsp;
                    <select
                      className="bg-[#DFDCF7] px-2 py-1 rounded-2xl"
                      value={sortField}
                      onChange={(e) => setSortField(e.target.value)}
                    >
                      <option value="PostedDate">Posted Date Hello </option>
                      <option value="ExpiryDate">Expire Date</option>
                    </select>
                    <button
                      className="bg-[#DFDCF7] px-2 py-1 rounded-2xl ml-4 hidden"
                      onClick={() =>
                        setSortOrder(sortOrder === "asc" ? "desc" : "asc")
                      }
                    >
                      {sortOrder === "asc" ? "Ascending" : "Descending"}
                    </button>
                  </div>
                </div>
                <div className="flex justify-center w-[20vw]">
                  <button
                    className="text-white rounded-xl h-[7vh] sm:px-4 hidden shadow hover:shadow-xl transition-all font-bold duration-400 transform hover:scale-105"
                    onClick={handleEditProfile}
                    style={{ background: "#393285" }}
                  >
                    Edit Profile
                  </button>
                </div>
              </div>
              {/* <div>
                {jobs && jobs.length > 0 ? (
                  jobs.map((job, index) => (
                    <div
                      key={index}
                      className="rounded-2xl flex flex-col md:flex-row justify-between items-left p-4 relative sm:mb-5 mb-5 mt-2 bg-[#DFDCF7] shadow hover:shadow-xl transition-shadow duration-200"
                    >
                      <div className="flex flex-row">
                        <div className="space-y-4 md:space-y-8 text-left w-[60vw]">
                          <div className="text-2xl font-semibold text-[#393285]">
                            {job.companyName}
                          </div>
                          <div className="flex flex-col md:flex-row space-x-0 space-y-4 md:space-y-0 md:space-x-16 text-black  font-semibold md:text-3xl">
                            <div className="w-2/3 ">
                              Posted on: {formatDate(job.PostedDate)}
                            </div>
                            <div className="w-2/3 ">
                              Expire on: {formatDate(job.ExpiryDate)}
                            </div>
                            <div className="w-2/3 ">
                              Job Status: {job.status || "Pending"}
                            </div>
                          </div>
                        </div>
                      </div>

                      <button
                        className={`absolute sm:static right-4 bottom-1 bg-white text-black px-4 py-2 mb-30 rounded-2xl text-xl text-center transition-colors duration-300 ease-in-out ${
                          job.status === "rejected"
                            ? "cursor-not-allowed opacity-50"
                            : "cursor-pointer hover:bg-gray-200 hover:shadow-xl transition-shadow duration-200"
                        }`}
                        onClick={() => {
                          if (
                            job.status === "accepted" ||
                            job.status === "waiting"
                          ) {
                            handleCheckResponses(job.documentId);
                          } else if (job.status === "waiting") {
                            handleWaiting();
                          }
                        }}
                        disabled={job.status === "rejected"} // Disable button if status is rejected
                      >
                        Check Responses
                      </button>
                    </div>
                  ))
                ) : (
                  <div className="font-semibold text-warning-red text-2xl ml-96">
                    No jobs available...
                  </div>
                )}
              </div> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Frame18;
