import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
// import { useParams } from "react-router-dom";
import Navemployee from "../Sections/Navbar-3&5/Navbar_employee";
import Frame10 from "../Frames/Frame-10";
import axios from "axios";
import Swal from "sweetalert2";
import HashLoader from "react-spinners/HashLoader";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { FaRegCalendar } from "react-icons/fa6";
function Section6() {
  const navigation = useNavigate();
  const t1 = localStorage.getItem("token");
  const [loadin, setLoadin] = useState(true);
  const [profileImageURL, setProfileImageURL] = useState("/profile.jpg"); // Default fallback image
  const [resumePdfURL, setResumePdfURL] = useState(null);

  const [formData, setFormData] = useState({
    // uid: token,
    name: "",
    salary: Number(""),
    profile: null,
    resumePdf: null,
    video: null,
    govtId: null,
    email: "",
    gender: "",
    mobileNumber: Number(""),
    currentAddress: "",
    selectedState: "",
    selectedCity: "",
    dob: "",
    workexperience: Number(""),
    pincode: Number(""),
    education: "",
    pastjobexperience: "",
    language: "",
    skills: "",
    status: "waiting",
  });

  let timerInterval;
  let loading = false;

  function handleProgress() {
    Swal.fire({
      title: "Profile has been updating .....",
      html: "",
      timerProgressBar: loading,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        // console.log("I was closed by the timer");
      }
    });
  }

  function handleaSucessclick() {
    Swal.fire({
      title: "Success",
      text: "Your Profile has been updated",

      icon: "success",
      confirmButtonText: "ok",
    }).then((result) => {
      if (result.isConfirmed) {
        navigation("/jobsearch");
      }
    });
  }

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PORT}/api/updateprofiledata`,
          {
            type: process.env.REACT_APP_TYPE_EMPLOYER,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${t1}`, // Uncomment if authorization is needed
            },
          }
        );

        const profileData = response.data;
        // setAllowRender(true);
        // console.log(profileData);
        if (!response.data.message) {
          setFormData(profileData);

          if (profileData.profile) {
            setProfileImageURL(profileData.profile);
          }
          if (profileData.resume) {
            setFormData({
              ...profileData,
              resumePdf: profileData.resume,
            });
            setResumePdfURL(profileData.resume);
          }
        }
      } catch (err) {
        // console.log("Error fetching profile data:", err);
        // localStorage.removeItem("token");
        if (!toast.isActive("invalid-credentials-toast")) {
          toast.error("Invalid Credentials", {
            toastId: "invalid-credentials-toast",
          });
        }
        navigation("/");
      } finally {
        setLoadin(false);
      }
    };

    fetchProfileData(); // Call the function once the token is available
  }, [t1, navigation]);

  // if (!allowRender) {
  //   return null;
  // }

  const today = new Date();
  const eighteenYearsAgo = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );

  const dobParts = formData.dob ? formData.dob.split("-") : null;

  const [selectedYear, setSelectedYear] = useState(
    dobParts ? parseInt(dobParts[0]) : eighteenYearsAgo.getFullYear()
  );

  const [displayedMonth, setDisplayedMonth] = useState(
    dobParts
      ? new Date(
          parseInt(dobParts[0]),
          parseInt(dobParts[1]) - 1,
          parseInt(dobParts[2])
        )
      : new Date(selectedYear, 0, 1)
  );

  // Ensure displayedMonth updates if formData.dob changes later
  useEffect(() => {
    const newDobParts = formData.dob.split("-");

    setDisplayedMonth(
      new Date(
        parseInt(newDobParts[0]),
        parseInt(newDobParts[1]) - 1,
        parseInt(newDobParts[2])
      )
    );
    setSelectedYear(parseInt(newDobParts[0]));
  }, [formData.dob]); // Runs whenever formData.dob changes

  const startYear = today.getFullYear() - 18; // 18 years before today (2007 in 2025)
  const endYear = 1900; // The last year in the list

  const years = Array.from(
    { length: startYear - endYear + 1 },
    (_, i) => startYear - i
  );

  const [isCalendarOpen, setISCalendarOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChang = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Allowed image types
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (!allowedTypes.includes(file.type)) {
      toast.warn("Please upload an image in JPEG, JPG and PNG format");
      return;
    }

    if (file.size > 1048576) {
      toast.warn("Image size should be less than 1MB");
      return;
    }
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImageURL(imageUrl);
      setFormData({
        ...formData,
        [event.target.name]: event.target.files[0], // Only one file
      });
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    if (file.type !== "application/pdf") {
      toast.warn("Please upload a PDF file!");
      return;
    }
    if (file.size > 1048576) {
      toast.warn("File size should be less than 1MB!");
      return;
    }

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setResumePdfURL(imageUrl);

      setFormData({
        ...formData,
        [event.target.name]: event.target.files[0], // Only one file
      });
    }
  };

  const validateForm = () => {
    const {
      // profile,
      name,
      email,
      gender,
      mobileNumber,
      currentAddress,
      dob,
      selectedState,
      selectedCity,
      workexperience,
      pincode,
      education,
      language,
      resumePdf,
      // skills,
    } = formData;

    // if (!profile) return "Profile is required";
    if (!name) return "Name is required";
    if (!email || !/\S+@\S+\.\S+/.test(email)) return "Valid email is required";
    if (!gender) return "Gender is required";
    if (!mobileNumber || !/^\d{10}$/.test(mobileNumber))
      return "Valid 10-digit number is required";
    if (!currentAddress) return "Address is required";
    if (!dob) return "Date of Birth is required";
    if (!selectedState) return "State is required";
    if (!selectedCity) return "City is required";
    if (!workexperience) return "Work Experience is required";
    if (!pincode || !/^\d{6}$/.test(pincode))
      return "Valid Pincode is required";
    if (!education) return "Education is required ";
    if (!language) return "Language is required";
    if (!resumePdf) return "Resume is required";
    // if (!skills) return "Skills is required";

    const birthDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();

    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    if (age < 18) return "You must be at least 18 years old";

    return null; //No errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const error = validateForm();

    if (error) {
      toast.warn(error);
      return;
    }

    const formDataToSend = new FormData();
    // console.log("Form submitted!", formData);
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null) {
        formDataToSend.append(key, value);
      }
    });
    formDataToSend.append("type", process.env.REACT_APP_TYPE_EMPLOYER);
    loading = true;

    // console.log("Form data to send:", formDataToSend, formDataToSend.resumePdf);
    handleProgress();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/api/upload`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${t1}`, // Uncomment if authorization is needed
          },
        }
      );

      handleaSucessclick();

      if (response.data.profileUrl) {
        setProfileImageURL(response.data.profileUrl);
      }
      if (response.data.resumePdfUrl) {
        setResumePdfURL(response.data.resumePdfUrl);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  return (
    <>
      <div className="font-inter h-full w-full  overflow-x-hidden">
        {loadin ? (
          <HashLoader
            color="#34a5f3"
            size={60} // Adjust the size as needed
            cssOverride={{
              position: "absolute", // Ensure the loader is positioned relative to the viewport
              top: "50%", // Center vertically
              left: "50%", // Center horizontally
              transform: "translate(-50%, -50%)", // Adjust to truly center the loader
            }}
            loading={true}
          />
        ) : (
          <div>
            <Navemployee />
            <div className="flex justify-center mt-4  px-2 pt-8">
              <h2 className="text-2xl sm:text-3xl font-bold text-primary-blue sm:ml-8 text-center">
                Edit/Manage Your Profile
              </h2>
            </div>
            <form
              onSubmit={handleSubmit}
              className=" flex justify-center items-center  "
            >
              <div className=" w-full flex flex-col sm:flex-row items-center justify-between mt-4 gap-1 ">
                <div className="flex items-center justify-center sm:justify-end sm:w-2/6 sm:self-baseline ">
                  <div className="h-20 w-20 sm:mr-8 sm:mt-4">
                    {/* Display profile image */}
                    <img
                      className="h-full w-full rounded-full object-cover cursor-pointer border border-black-10"
                      src={profileImageURL}
                      alt="Profile"
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      } // Ensure the ID matches
                    />

                    {/* Hidden file input */}
                    <input
                      id="fileInput" // Use the correct ID
                      type="file" // Correct the type to "file"
                      name="profile"
                      accept="image/*" // Ensure you specify valid input types
                      className="hidden" // Hide the input visually
                      onChange={handleFileChang} // Handle file selection
                    />
                  </div>
                </div>

                <div className=" realtive w-full sm:w-4/6 mx-auto ">
                  <div className="flex flex-col sm:flex-row sm:justify-start sm:items-center gap-3 mb-2 flex-wrap justify-center items-center mx-auto mt-3 ">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Your Full name*"
                      className="placeholder-black  placeholder:text-ssm   bg-blue-10  text-sm sm:text-base text-center text-black   rounded-full py-2 px-2 w-[75%] sm:w-[25%] "
                    />
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email ID*"
                      className="placeholder-black placeholder:text-ssm   bg-blue-10 text-sm sm:text-base text-center text-black   rounded-full py-2 px-2 w-[75%] sm:w-[25%] "
                    />

                    <input
                      type="text"
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                      className="placeholder-black placeholder:text-ssm    bg-blue-10 text-sm sm:text-base text-center text-black   rounded-full py-2 px-2 w-[75%] sm:w-[23%] "
                      placeholder="Gender"
                    />
                  </div>
                  <div className="flex flex-col sm:flex-row sm:justify-start sm:items-center gap-3 mb-2 justify-center items-center sm:mt-3">
                    <input
                      type="number"
                      name="mobileNumber"
                      value={formData.mobileNumber}
                      onChange={handleChange}
                      placeholder="Mobile Number"
                      className="placeholder-black placeholder:text-ssm    bg-blue-10 text-sm sm:text-base text-center text-black   rounded-full py-2 px-2 w-[75%] sm:w-[25%]  "
                    />
                    <input
                      type="text"
                      name="currentAddress"
                      value={formData.currentAddress}
                      onChange={handleChange}
                      placeholder="Company Address"
                      className="placeholder-black placeholder:text-ssm    bg-blue-10  text-sm sm:text-base text-center text-black   rounded-full py-2 px-3 w-[75%] sm:w-[50%] "
                    />
                  </div>
                  <div className="flex flex-col sm:flex-row sm:justify-start sm:items-start gap-3 mb-2 justify-center items-center  sm:mt-3 ">
                    <div className="w-[75%] sm:w-[33%] relative ">
                      <input
                        type="text"
                        readOnly
                        value={
                          formData.dob
                            ? format(new Date(formData.dob), "yyyy-MM-dd")
                            : ""
                        }
                        onClick={() => setISCalendarOpen(!isCalendarOpen)}
                        placeholder="Date of Birth*"
                        className="placeholder-black placeholder:text-ssm   bg-blue-10 text-sm sm:text-base text-center text-black cursor-pointer   rounded-full py-2 px-2 w-full  "
                      />

                      <div
                        className="absolute lg:inline sm:hidden inline right-4 top-3 cursor-pointer"
                        onClick={() => setISCalendarOpen(!isCalendarOpen)}
                      >
                        <FaRegCalendar />
                      </div>

                      {isCalendarOpen && (
                        <div className="absolute z-50 py-1 border border-black-10 px-1 text-sm bg-white top-12">
                          {/* Year Dropdown for Quick Selection */}
                          <select
                            value={selectedYear}
                            onChange={(e) => {
                              const year = Number(e.target.value);
                              setSelectedYear(year);
                              setDisplayedMonth(new Date(year, 0, 1)); // 👈 Update the displayed month dynamically
                            }}
                            className="w-full mb-2 p-2 border-2 rounded-md  "
                          >
                            {years.map((year) => (
                              <option
                                key={year}
                                value={year}
                                className="border h-[200px]"
                              >
                                {year}
                              </option>
                            ))}
                          </select>

                          <DayPicker
                            mode="single"
                            month={displayedMonth} // 👈 Controlled month updates dynamically
                            selected={
                              formData.dob ? new Date(formData.dob) : undefined
                            }
                            onMonthChange={setDisplayedMonth} // 👈 Allow user to navigate manually too
                            onSelect={(date) => {
                              if (date) {
                                const formattedDate = format(
                                  date,
                                  "yyyy-MM-dd"
                                );
                                setFormData((prev) => ({
                                  ...prev,
                                  dob: formattedDate,
                                }));
                                setISCalendarOpen(false);
                              }
                            }}
                            disabled={[
                              { from: eighteenYearsAgo, to: today }, // ❌ Disable under-18 dates
                              {
                                from: today,
                                to: new Date(
                                  today.getFullYear() + 100,
                                  today.getMonth(),
                                  today.getDate()
                                ),
                              }, // ❌ Disable future dates
                            ]}
                            className="custom-calendar"
                          />
                        </div>
                      )}
                    </div>
                    <div className="gap-3 w-full flex flex-row justify-center items-start sm:justify-start">
                      <input
                        type="text"
                        name="selectedState"
                        value={formData.selectedState}
                        onChange={handleChange}
                        placeholder="State*"
                        className="placeholder-black placeholder:text-ssm  placeholder:text-ssm  bg-blue-10 text-sm sm:text-base text-center text-black   rounded-full py-2 px-2 w-[36%] sm:w-[25%] "
                      />

                      <input
                        type="text"
                        name="selectedCity"
                        value={formData.selectedCity}
                        onChange={handleChange}
                        placeholder="City*"
                        className="placeholder-black  placeholder:text-ssm  bg-blue-10 text-sm sm:text-base text-center text-black   rounded-full py-2 px-2 w-[36%] sm:w-[25%]  "
                      />
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row sm:justify-start sm:items-start gap-3 mb-2 justify-center items-center sm:mt-3">
                    <select
                      name="workexperience"
                      value={formData.workexperience}
                      onChange={handleChange}
                      className="bg-blue-10 text-sm sm:text-base text-center text-black   rounded-full py-2 px-2 w-[75%] sm:w-[33%]"
                    >
                      <option value="" disabled>
                        Select Work Experience
                      </option>
                      <option value="fresher">Fresher</option>
                      <option value="0-1 yr">0-1 yr</option>
                      <option value="1-2 yr">1-2 yr</option>
                      <option value="more than 2 years">
                        More than 2 years
                      </option>
                    </select>

                    <div className="gap-3 w-full flex flex-row justify-center items-start sm:justify-start">
                      <input
                        type="text"
                        name="pincode"
                        value={formData.pincode}
                        onChange={handleChange}
                        placeholder="Pincode"
                        className="placeholder-black placeholder:text-ssm  placeholder:text-ssm  bg-blue-10  text-sm sm:text-base text-center text-black   rounded-full py-2 px-2 w-[36%] sm:w-[25%] "
                      />
                      <input
                        type="text"
                        name="education"
                        value={formData.education}
                        onChange={handleChange}
                        placeholder="Education Qualification"
                        className="placeholder-black placeholder:text-ssm    bg-blue-10 text-sm sm:text-base text-center text-black   rounded-full py-2 px-2 w-[36%] sm:w-[25%] "
                      />
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row sm:justify-start sm:items-start gap-3 mb-2 justify-center items-center sm:mt-3">
                    {["0-1 yr", "1-2 yr", "more than 2 years"].includes(
                      formData.workexperience
                    ) && (
                      <textarea
                        name="pastjobexperience"
                        value={formData.pastjobexperience}
                        onChange={handleChange}
                        placeholder="  Share your past/current job experience"
                        className="placeholder-black placeholder:text-ssm bg-blue-10 text-sm sm:text-base text-center text-black   rounded-2xl py-2 px-2 resize-none w-[75%] h-[15vh]"
                      />
                    )}
                  </div>
                  <div className="flex flex-col sm:flex-row sm:justify-start sm:items-start gap-3 sm:gap-8 mb-2 justify-center items-center">
                    {/* Text Input for Languages */}
                    <input
                      type="text"
                      name="language"
                      value={formData.language}
                      onChange={handleChange}
                      placeholder="Languages known"
                      className="placeholder-black placeholder:text-ssm  bg-blue-10 text-sm sm:text-base text-center text-black   rounded-full py-2 px-2  w-[75%] sm:w-[32%]"
                    />

                    {/* File Input for Resume */}
                    <label
                      htmlFor="resume-upload"
                      className="placeholder-black placeholder:text-ssm  bg-blue-10 text-sm sm:text-base text-center text-black   rounded-full py-2 px-2 w-[75%] sm:w-[32%] cursor-pointer"
                    >
                      {formData.resumePdf
                        ? "Change File"
                        : "Upload your CV/Resume"}
                      <input
                        id="resume-upload"
                        type="file"
                        name="resumePdf"
                        accept="application/pdf"
                        onChange={handleFileChange}
                        className="hidden"
                      />
                    </label>

                    {formData.resumePdf && (
                      <div className="file-preview mt-3">
                        <a
                          href={resumePdfURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary-blue underline text-sm sm:text-base"
                        >
                          View Resume
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col sm:flex-row sm:justify-start sm:items-start gap-3 mb-2 justify-center items-center sm:mt-3">
                    <textarea
                      type="text"
                      name="skills"
                      value={formData.skills}
                      onChange={handleChange}
                      placeholder="Mention your any other skills "
                      className="placeholder-black placeholder:text-ssm  bg-blue-10 text-sm sm:text-base  text-center text-black   rounded-2xl py-2 px-2 resize-none  w-[75%]  sm:w-[50%] h-[10vh]"
                    />
                  </div>{" "}
                  {/* <div className="bg-red-700 w-[42vw] flex flex-col sm:flex-row mt-4 justify-center items-center sm:justify-start sm:items-start"> */}
                  {/* <div className="flex flex-col sm:flex-row sm:justify-start sm:items-start gap-3 sm:gap-8 mb-2 justify-center items-center">
                    <label
                      htmlFor="govt-id-upload"
                      className="bg-blue-10 placeholder:text-ssm  text-sm sm:text-base text-center text-black   rounded-full py-2 px-2 sm:w-[32%] w-[75%] cursor-pointer"
                    >
                      {formData.govtId
                        ? "Change File"
                        : "Upload any Govt. ID proof"}
                      <input
                        id="govt-id-upload"
                        type="file"
                        name="govtId"
                        accept="application/pdf,image/*"
                        onChange={handleFileChange}
                        className="hidden"
                      />
                    </label>
                    {formData.govtId && (
                      <div className="file-preview">
                        <a
                          href={formData.govtId}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary-blue underline text-sm sm:text-base"
                        >
                          Govt ID
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col sm:flex-row sm:justify-start sm:items-start gap-3 sm:gap-8 mb-2 justify-center items-center">
                    <label
                      htmlFor="video-upload"
                      className="bg-blue-10 text-sm sm:text-base text-center text-black   rounded-xl sm:rounded-full py-2 px-2 sm:w-[75%] w-[75%] cursor-pointer"
                    >
                      {formData.video
                        ? "Change File"
                        : "Upload your one-minute audio/video introduction for smooth selection"}
                      <input
                        id="video-upload"
                        type="file"
                        name="video"
                        accept="video/*"
                        onChange={handleFileChange}
                        className="hidden"
                      />
                    </label>
                    {formData.video && (
                      <div className="file-preview">
                        <a
                          href={formData.video}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary-blue underline text-sm sm:text-base"
                        >
                          Introductory Video
                        </a>
                      </div>
                    )}
                  </div> */}
                  {/* Add more input fields as needed */}
                  <div className="flex flex-col sm:flex-row sm:justify-center sm:items-start gap-3 sm:gap-8 mb-5 mt-5 justify-center items-center">
                    <button
                      type="submit"
                      className="bg-primary-blue text-white  rounded-full py-2 px-12 text-base hover:scale-110 transition-all duration-300"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className=" h-full w-full  ml-2 mb-5 mt-10 flex flex-col gap-y-6 ">
              <h1 className=" sm:text-2xl font-inter text-xl font-semibold ">
                My Applications &gt;
              </h1>
              <Frame10 applied={"true"} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Section6;
