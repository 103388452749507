import React, { useEffect, useState } from "react";
import HorizontalScroll from "./Desktop1/companys_scroll";
import { auth, googleProvider } from "./firebase";
import { signInWithPopup } from "firebase/auth";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import axios from "axios";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import Navbar from "./Desktop1/Navbar";
import Footer from "./Desktop1/Footer2";

const Desktop3 = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const { setIsAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (token) {
      navigate("/employee_dashboard");
    }
  }, [token, navigate]);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      // testing code
      const idToke = await user.getIdTokenResult();
      if (!idToke.claims.role) {
        await setRoleOnBackend(user.uid, "employee"); // Set role as "employee" for new users
        await getTokenFromServer(user.uid, "employee"); // Reuse the external function
        toast.success("Google Sign-In successful!");
        navigate("/employee_dashboard");
      } else {
        const role = idToke.claims.role;
        // console.log("Role detected: ", role);
        if (role === process.env.REACT_APP_TYPE_EMPLOYEE) {
          await getTokenFromServer(
            user.uid,
            process.env.REACT_APP_TYPE_EMPLOYEE
          ); // Reuse the external function
          toast.success("Google Sign-In successful!");
          navigate("/employee_dashboard");
          // Redirect to admin dashboard
        } else if (
          role === process.env.REACT_APP_TYPE_EMPLOYEE ||
          role === process.env.REACT_APP_TYPE_ADMIN
        ) {
          toast.error("Access Denied for this email");
        } else {
          toast.error("Invalid role detected. Please contact support.", role);
        }
      }

      // Fetch token from server and store in localStorage
    } catch (error) {
      console.error("Error during Google Sign-In: ", error);
      if (!toast.isActive("google-credentials-toast")) {
        toast.error("Google Sign-In failed. Please try again.", {
          toastId: "google-credentials-toast",
        });
      }
    }
  };

  const getTokenFromServer = async (uid, role) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PORT}/generateToken`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uid, type: role }), // Pass UID and role as necessary data
        }
      );

      if (!response.ok) {
        throw new Error("Failed to retrieve token");
      }

      const data = await response.json();
      localStorage.setItem("token", data.token);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Error fetching token:", error);
      throw error; // Rethrow to be caught in the outer try-catch
    }
  };

  const setRoleOnBackend = async (uid, role) => {
    try {
      await fetch(`${process.env.REACT_APP_PORT}/setRole`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid, role }),
      });
    } catch (error) {
      console.error("Error setting role:", error);
    }
  };

  const [countryCode, setCountryCode] = useState("+91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpSend, setOtpSend] = useState(false);

  const handleVerifyOtp = async (e) => {
    setOtpSend(true);
    setLoading(true);
    e.preventDefault();

    if (!otp) {
      toast.error("Please enter the OTP");
      setOtpSend(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/verify-otp`,
        {
          phoneNumber: countryCode + phoneNumber,
          otp: otp,
        }
      );

      if (response.data.message === "OTP verified successfully") {
        const responso = await fetch(
          `${process.env.REACT_APP_PORT}/generateToken`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: response.data.phoneNumber,
              type: process.env.REACT_APP_TYPE_EMPLOYEE,
            }), // Pass UID or any other necessary data
          }
        );
        const data = await responso.json();
        localStorage.setItem("token", data.token);
        setIsAuthenticated(true);
        toast.success("OTP verified successfully!");
        localStorage.setItem("type", "employee");
        navigate("/employee_dashboard");
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("There was an error verifying the OTP.");
    } finally {
      setOtpSend(false);
      setLoading(false);
    }
  };

  const handleSendOtp = async (e) => {
    setLoading(true);
    setOtpSend(true);
    e.preventDefault();

    if (!phoneNumber || phoneNumber.length !== 10) {
      toast.error("Please enter a valid 10-digit phone number");
      setTimeout(() => {
        setOtpSend(false);
        setLoading(false);
      }, 500);

      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/send-otp`,
        {
          phoneNumber: countryCode + phoneNumber,
        }
      );

      if (response.data.message === "OTP sent successfully") {
        toast.success("OTP sent successfully!");
        setOtpSent(true);
      } else {
        toast.error("Failed to send OTP.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("There was an error sending the OTP.");
    } finally {
      setOtpSend(false);
      setLoading(false);
    }
  };

  return (
    <>
      {/* full page loading effect */}
      {/* {loading ? (
        <HashLoader
          color="#34a5f3"
          size={60} // Adjust the size as needed
          cssOverride={{
            position: "absolute", // Ensure the loader is positioned relative to the viewport
            top: "50%", // Center vertically
            left: "50%", // Center horizontally
            transform: "translate(-50%, -50%)", // Adjust to truly center the loader
          }}
        />
      ) : ( */}
      <Navbar />
      <section className="py-10 w-full  font-inter">
        {/* ------------ container ---------------- */}

        <div className="xl:w-10/12 xs:w-11/12 xsm:w-[95%] w-[96%] mx-auto  flex flex-col gap-y-4">
          {/* -------- 1st section -------- */}

          <div className="flex xmd:flex-row flex-col  xl:gap-x-8 gap-x-6 gap-y-12 justify-between ">
            {/* ----- left part ---- */}

            <div className="xl:w-[46%] xmd:w-[43%] md:w-[80%] sm:w-[90%] mx-auto flex flex-col gap-y-4 pr-6 xs:pr-0">
              <h1
                className=" lg:text-[40px] text-4xl font-semibold xl:w-[80%] w-[90%] 
                 sm:leading-[56px] leading-[48px] text-blue-200 "
              >
                Powering Your Hiring Success!!
              </h1>
              <p className="text-lg sm:text-xl font-semibold ">
                Log in now and experience the future of hiring with Hirers.
              </p>
            </div>

            {/* ------- right part ----- */}
            <div className="xl:w-[45%] xmd:w-[50%] md:w-[80%] sm:w-[90%]  w-full  mx-auto  bg-light-purple-100 py-4 xl:px-10 sm:px-6 px-4  rounded-[32px] flex flex-col gap-y-6 items-center ">
              <h2 className=" xl:text-xl xs:text-lg font-semibold text-center">
                Employer LogIn/SignUp
              </h2>

              <form
                onSubmit={otpSent ? handleVerifyOtp : handleSendOtp}
                className="flex flex-col gap-y-4"
              >
                <div className="flex xl:gap-x-8 xsm:gap-x-4 gap-x-1 sm:justify-between justify-center items-center sm:text-lg text-base">
                  <input
                    type="text"
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                    placeholder="+91"
                    className="py-3 px-2 sm:w-[60px] xsm:w-[48px] w-[44px] rounded-lg text-black placeholder-black font-medium"
                  />
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Mobile Number"
                    className="py-3 px-4 rounded-lg placeholder-black text-black font-medium"
                  />
                </div>
                {otpSent && (
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter OTP"
                    className="py-3 px-4 rounded-lg placeholder-black text-black font-medium"
                  />
                )}

                <div className="flex justify-center">
                  <button
                    disabled={otpSend}
                    type="submit"
                    className="xs:w-full w-[80%] bg-blue-200 text-white flex items-center justify-center py-2 rounded-lg xl:text-xl xsm:text-lg text-base transition-all duration-300 hover:bg-blue-300 hover:scale-105 px-4"
                  >
                    {/* btn loading effect */}
                    {loading ? (
                      <p className="loader"></p>
                    ) : otpSent ? (
                      "Verify OTP"
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </form>

              <div className="flex flex-col gap-y-3 items-center">
                <p className=" text-base font-medium text-center">
                  Click here to login using Google/FB account
                </p>
                <div className="flex gap-x-4">
                  <LazyLoadImage
                    src="/google.png"
                    alt="Google"
                    effect="blur"
                    className="sm:w-8 sm:h-8 w-7 h-7 cursor-pointer transition-all duration-300  hover:scale-110"
                    onClick={handleGoogleSignIn}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ------- 2nd section ------ */}

          <div className=" font-semibold text-base  mt-6 lg:mt-0 mb-3 ">
            <p className="text-black">Looking for a Job?</p>
            <button
              className="text-blue-200 transition-all duration-300 hover:text-blue-300 hover:scale-105"
              onClick={() => navigate("/candidate_login")}
            >
              Click Here
            </button>
          </div>

          {/* ------- 3rd section --- */}
          {/* 
          <div className=" py-2">
            <div className="hidden xmd:flex flex-col gap-y-12">
              <h2 className="text-center font-semibold text-blue-200 text-3xl">
                Companies that hire from us
              </h2>

              <div>
                <HorizontalScroll/>
              </div>


             
            </div>


          </div> */}
        </div>

        <HorizontalScroll data="Company that hire from us" className="w-full" />
      </section>
      <Footer />
      {/* )} */}
    </>
  );
};

export default Desktop3;
