import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import axios from "axios";
import { toast } from "react-toastify";
import Navemployee from "../Sections/Navbar-3&5/Navbar_employee";
import { PiMoneyWavyDuotone } from "react-icons/pi";
import { SlLocationPin } from "react-icons/sl";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Frame12() {
  const t1 = localStorage.getItem("token");
  const [view, setView] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();
  const { jobId } = useParams();
  const [jobData, setJobData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allowRender, setAllowRender] = useState(false);
  const [showDesc, setShowDesc] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [appliedJob, setAppliedJob] = useState(
    location.state
      ? location.state.alreadyApply === "true"
        ? true
        : false
      : false
  );

  const handleDesc = () => {
    setShowDesc((prev) => !prev);
  };

  const handleClick = () => {
    if (!appliedJob) {
      toast.warn("Apply for the job to view details");
    } else {
      setView(true);
    }
  };

  // const [error, setError] = useState(null);

  // const state = location.state ? location.state : "";
  // console.log(location.state)
  // console.log(state);

  // useEffect(() => {
  //   if (location.state) {
  //     if (isApplied !== location.state.alreadyApply) { // ✅ Only update if different
  //       setIsApplied(location.state.alreadyApply);
  //     }
  //   } else {
  //     if (isApplied !== false) { // ✅ Avoid unnecessary re-renders
  //       setIsApplied(false);
  //     }
  //   }

  //   console.log(location.state.alreadyApply)
  // }, []);

  // console.log("Current state.alreadyApply:", state.alreadyApply);

  const handledoc = (jobId) => {
    // setIsApplied(true);
    navigate(`/applyform/${jobId}`); // Navigate to Section3 with jobId
  };

  // const jobData = {
  //   jobTitle: "Software Engineer",
  //   companyName: "TechCorp",
  //   companyAddress: "123 Silicon Valley",
  //   jobLocation: "San Francisco, CA",
  //   salary: "$120,000",
  //   educationQualification: "Bachelor's in Computer Science and dbh dhuysb",
  //   experienceLevel: "3-5 years",
  //   communicationSkill: "Excellent",
  //   Description:
  //     "Develop and maintain web applications using modern frameworks. ",
  //   Gender: "Any",
  //   similarJobs: [
  //     "Frontend Developer - San Francisco, CA",
  //     "Backend Developer - Seattle, WA",
  //   ],
  // };

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PORT}/api/jobs/${jobId}`,
          {
            headers: {
              Authorization: `Bearer ${t1}`,
            },
          }
        );

        setAllowRender(true);
        setJobData({ ...response.data, id: jobId }); // Set jobData without modifying it directly
      } catch (err) {
        if (!toast.isActive("invalid-credentials-toast")) {
          toast.error("Session timeout error. Please Login Again", {
            toastId: "invalid-credentials-toast",
          });
          localStorage.clear();
        }
        navigate("/");
      } finally {
        setLoading(false);
      }
    };

    fetchJobData();
  }, [jobId, t1, navigate]); // ✅ Removed `jobData`

  if (!allowRender) {
    return null;
  }

  // console.log("IsAPPPPPP: " , isApplied);

  return (
    <>
      <div className="h-full w-full ">
        {loading ? (
          <HashLoader
            color="#34a5f3"
            size={60} // Adjust the size as needed
            cssOverride={{
              position: "absolute", // Ensure the loader is positioned relative to the viewport
              top: "50%", // Center vertically
              left: "50%", // Center horizontally
              transform: "translate(-50%, -50%)", // Adjust to truly center the loader
            }}
            loading={true}
          />
        ) : (
          <div className="h-full w-full  ">
            <Navemployee />
            <div className="w-full bg-white flex flex-col font-ABeeZee ">
              {/* Header Section */}
              <div className="w-full sm:text-white p-3 flex sm:flex-row sm:bg-primary-blue sm:mt-0 mt-2">
                {/* Company Logo (Image) */}
                <div className="w-12 h-12 sm:hidden flex justify-center items-center rounded-full overflow-hidden border">
                  <img
                    src="/profile.jpg"
                    alt={`${jobData.companyName} logo`}
                    className="w-full h-full object-cover sm:rounded-full"
                  />
                </div>

                {/* Job Information */}
                <div className="w-3/4 sm:w-3/4 flex flex-col font-abeezee ml-3 sm:ml-8 sm:text-lg text-base sm:font-normal font-medium">
                  <h2 className="text-lg sm:text-2xl md:text-3xl sm:mb-2 ">
                    {jobData.jobTitle}
                  </h2>
                  <p className="mb-2 text-secondary-blue sm:text-white">
                    {jobData.companyName}
                  </p>

                  {/* Address and Location with Small Rounded Images */}
                  <div className="w-full flex items-center mb-2 xs:mt-3 sm:mt-0 ">
                    <div className=" sm:hidden flex justify-center items-center mr-3">
                      <SlLocationPin className="text-2xl" />
                    </div>
                    <p className="sm:mb-2 text-base sm:text-lg">
                      {jobData.jobLocation}, {jobData.jobCity}-
                      {jobData.jobPincode}, {jobData.jobState}
                    </p>
                  </div>

                  {/* Salary with Small Rounded Icon */}
                  <div className="w-full flex items-center mb-2">
                    <div className="  sm:hidden flex justify-center items-center mr-3">
                      <PiMoneyWavyDuotone className="text-2xl" />
                    </div>
                    <p className="text-md sm:text-xl">{jobData.salary}</p>
                  </div>

                  <button
                    className="w-36 bg-white text-black mt-2 py-[6px] px-2 hidden sm:block rounded-lg text-base"
                    onClick={() => handledoc(jobData.id)}
                  >
                    Category
                  </button>
                </div>

                {/* Apply Now Button */}
                <div className="sm:flex items-end w-full xmd:justify-start justify-end xmd:w-max xl:ml-7   mt-4 sm:mt-0 hidden sm:mr-8 ">
                  <button
                    disabled={appliedJob}
                    className={` text-secondary-blue py-[6px] px-6 rounded-xl text-base font-semibold
                     ${appliedJob ? "bg-yellow-100" : "bg-white-100"} `}
                    onClick={() => {
                      // console.log("Button Clicked. Current isApplied:", isApplied);
                      handledoc(jobData.id);
                    }}
                  >
                    {appliedJob ? "Already Applied" : "Apply Now"}
                  </button>
                </div>
              </div>

              <div className=" bg-blue-50 p-3 rounded-2xl shadow-md  flex  justify-between sm:hidden mt-4 gap-2 m-2 text-sm">
                <a
                  href="#job-details"
                  className="px-4 py-1 bg-white text-black rounded-2xl "
                >
                  Job Details
                </a>
                <a
                  href="#job-desc"
                  className="px-4 py-1 bg-white text-black rounded-2xl "
                >
                  Job Description
                </a>
                <a
                  href="#hr-details"
                  className="px-4 py-1  bg-white text-black rounded-2xl"
                >
                  HR Details
                </a>
              </div>

              {/* ----------- new code here ------------ */}

              <div className=" py-10  sm:py-16">
                {/* ------- container --------- */}
                <div className="w-11/12 mx-auto  flex flex-col lg:flex-row  gap-y-6 justify-between">
                  {/* ------- job detail left part ------- */}

                  <div className="lg:w-[70%] flex flex-col gap-y-10">
                    {/* ----------- job details ---------- */}

                    <div className=" bg-blue-10  flex flex-col gap-y-6 py-4 px-4 rounded-xl font-abeezee">
                      <h3 className=" sm:text-3xl text-xl  font-abeezee text-center  font-semibold ">
                        Job Details
                      </h3>
                      <div className=" flex sm:flex-row flex-col gap-y-6 justify-between xmd:text-lg xs:text-base text-sm">
                        {/* --------- left box ----- */}
                        <div className="sm:w-[48%] xl:w-[45%] flex flex-col gap-y-3">
                          <div>
                            <p className="flex flex-col gap-y-0">
                              <span className="text-blue-200 font-semibold">
                                Education:{" "}
                              </span>
                              <span>
                                {Array.isArray(jobData.educationQualification)
                                  ? jobData.educationQualification
                                      .map((data) =>
                                        data === "10thPassed"
                                          ? "10th Passed"
                                          : data === "12thPassed"
                                          ? "12th Passed"
                                          : "Graduated and above"
                                      )
                                      .join(", ")
                                  : jobData.educationQualification ===
                                    "10thPassed"
                                  ? "10th Passed"
                                  : jobData.educationQualification ===
                                    "12thPassed"
                                  ? "12th Passed"
                                  : "Graduated and above"}
                              </span>
                            </p>
                          </div>

                          <div>
                            <p className="flex flex-col gap-y-0">
                              <span className="text-blue-200 font-semibold">
                                Experience{" "}
                              </span>
                              <span>
                                {Array.isArray(jobData.experienceLevel)
                                  ? jobData.experienceLevel
                                      .map((data) =>
                                        data === "fresher"
                                          ? "Fresher"
                                          : data === "0-1yr"
                                          ? "0-1 yr"
                                          : data === "1-2yr"
                                          ? "1-2 yr"
                                          : "More than 2 yr"
                                      )
                                      .join(", ")
                                  : jobData.experienceLevel === "fresher"
                                  ? "Fresher"
                                  : jobData.experienceLevel === "0-1yr"
                                  ? "0-1 yr"
                                  : jobData.experienceLevel === "1-2yr"
                                  ? "1-2 yr"
                                  : "More than 2 yr"}
                              </span>
                            </p>
                          </div>

                          <div>
                            <p className="flex flex-col gap-y-0">
                              <span className="text-blue-200 font-semibold">
                                Job Location:{" "}
                              </span>
                              <span>
                                {jobData.jobLocation},{jobData.jobState},
                                {jobData.jobCity},{jobData.jobPincode}
                              </span>
                            </p>
                          </div>

                          <div>
                            <p className="flex flex-col gap-y-1">
                              <span className="text-blue-200 font-semibold">
                                Communication Skill:{" "}
                              </span>
                              <span>
                                {Array.isArray(jobData.communicationSkill)
                                  ? jobData.communicationSkill
                                      .map((data) =>
                                        data === "hindi"
                                          ? "Hindi"
                                          : data === "englishNormal"
                                          ? "English (Normal Speaking)"
                                          : data === "englishIntermediate"
                                          ? "English (Intermediate)"
                                          : data === "englishFluent"
                                          ? "English (Fluent)"
                                          : "Others"
                                      )
                                      .join(", ")
                                  : jobData.communicationSkill === "hindi"
                                  ? "Hindi"
                                  : jobData.communicationSkill ===
                                    "englishNormal"
                                  ? "English (Normal Speaking)"
                                  : jobData.communicationSkill ===
                                    "englishIntermediate"
                                  ? "English (Intermediate)"
                                  : jobData.communicationSkill ===
                                    "englishFluent"
                                  ? "English (Fluent)"
                                  : "Others"}
                              </span>
                            </p>
                          </div>
                        </div>

                        {/* ------ right box ------ */}

                        <div className="sm:w-[48%] xl:w-[45%] flex flex-col gap-y-4">
                          <div>
                            <p className="flex flex-col gap-y-0">
                              <span className="text-blue-200 font-semibold">
                                Gender:{" "}
                              </span>
                              {jobData.gender === "Both"
                                ? "Male and Female"
                                : jobData.gender}
                            </p>
                          </div>

                          <div className="flex flex-col gap-y-2">
                            <p className="text-blue-200 font-semibold">
                              Interview Details:{" "}
                            </p>

                            <div className="flex flex-col gap-y-1">
                              <p className="flex gap-x-2">
                                <span className="font-semibold">Process: </span>
                                <span>
                                  {Array.isArray(jobData.interviewProcess)
                                    ? jobData.interviewProcess.join(", ")
                                    : jobData.interviewProcess}
                                </span>
                              </p>
                              <p className="flex gap-x-2">
                                <span className="font-semibold">
                                  Location:{" "}
                                </span>
                                {Array.isArray(jobData.interviewProcess) ? (
                                  jobData.interviewProcess.includes(
                                    "offline"
                                  ) ? (
                                    <span>
                                      {jobData.interviewLocation},{" "}
                                      {jobData.interviewCity},{" "}
                                      {jobData.interviewState},{" "}
                                      {jobData.interviewPincode}
                                    </span>
                                  ) : (
                                    <span>Remote</span>
                                  ) // Display "Remote" for non-offline interviews
                                ) : jobData.interviewProcess === "offline" ? (
                                  <span>
                                    {jobData.interviewLocation},{" "}
                                    {jobData.interviewCity},{" "}
                                    {jobData.interviewState},{" "}
                                    {jobData.interviewPincode}
                                  </span>
                                ) : (
                                  <span>Remote</span>
                                )}{" "}
                                {/* Display "Remote" when interview is not offline */}
                              </p>
                              {jobData.interviewTiming && (
                                <p className="flex gap-x-2">
                                  <span className="font-semibold">
                                    Timing:{" "}
                                  </span>
                                  <span>{jobData.interviewTiming}</span>
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="flex flex-col gap-y-2">
                            <p className="text-blue-200 font-semibold">
                              Work Details:{" "}
                            </p>

                            <div className="flex flex-col gap-y-1">
                              <p className="flex gap-x-2">
                                <span className="font-semibold">Type: </span>
                                <span>
                                  {/* {Array.isArray(jobData.workType)
                                    ? jobData.workType.join(", ")
                                    : jobData.workType} */}

                                  {Array.isArray(jobData.workType)
                                    ? jobData.workType
                                        .map((data) =>
                                          data === "hybrid"
                                            ? "Hybrid"
                                            : data === "home"
                                            ? "Work from Home"
                                            : "Work from Office"
                                        )
                                        .join(", ")
                                    : jobData.workType === "hybrid"
                                    ? "Hybrid"
                                    : jobData.workType === "home"
                                    ? "Work from Home"
                                    : "Work from Office"}
                                </span>
                              </p>
                              <p className="flex gap-x-2">
                                <span className="font-semibold">
                                  Working Hours:{" "}
                                </span>
                                <span>{jobData.workingHours} hrs</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* -------- job description ----------- */}

                    <div className="flex flex-col  gap-y-6">
                      <div className=" bg-blue-10  flex flex-col gap-y-6 py-4 px-4 rounded-xl font-abeezee ">
                        <h3 className=" sm:text-3xl text-xl  font-abeezee text-center  font-semibold  ">
                          Job Description
                        </h3>

                        <div>
                          <pre
                            className="font-abeezee sm:text-base text-sm "
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            {jobData.jobDescription
                              ? !showDesc
                                ? jobData.jobDescription.length > 200
                                  ? jobData.jobDescription.slice(0, 200) +
                                    "... "
                                  : jobData.jobDescription
                                : jobData.jobDescription + "... "
                              : "No description available."}

                            {jobData.jobDescription &&
                              jobData.jobDescription.length > 200 && (
                                <span
                                  onClick={handleDesc}
                                  className="text-blue-300 cursor-pointer font-bold"
                                >
                                  {showDesc ? "show less" : "read more"}
                                </span>
                              )}
                          </pre>
                        </div>
                      </div>

                      <div className="sm:hidden flex justify-end">
                        <button
                          className={` py-2 px-6 rounded-xl transition-all duration-300 hover:scale-110 ${
                            appliedJob ? "bg-yellow-100" : "bg-blue-100"
                          }
                          ${appliedJob ? "text-blue-100" : "text-white"}
                          `}
                          onClick={() => handledoc(jobData.id)}
                        >
                          {appliedJob ? "Already Applied" : "Apply Now"}
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* ------------ similar job right part ----------- */}

                  <div className="lg:w-[28%] flex flex-col gap-y-4 ">
                    <div id="hr-details" className="flex-2 ">
                      <h3 className="md:text-2xl text-xl text-center font-abeezee mt-4">
                        HR Details
                      </h3>
                      <div className="mt-1 xs:mt-2 text-xs md:text-sm text-gray-500 space-y-2">
                        <div className="text-white px-6 py-3 rounded-3xl text-lg flex flex-col items-center justify-center space-y-3">
                          {/* HR details box (Blurred if not applied) */}
                          <div
                            className={`bg-secondary-blue px-4 py-2 rounded-xl text-base w-max ${
                              appliedJob && view ? "" : "blur-sm"
                            }`}
                          >
                            Name: {jobData.hrname}
                            <br />
                            Contact: {jobData.hrmobileNumber}
                            <br />
                            Email: {jobData.hremail}
                          </div>

                          {/* Button for displaying toast message when not applied */}
                          <button
                            onClick={handleClick}
                            className="bg-blue-100 text-white px-4 py-2 rounded-lg mt-2 hover:bg-blue-10 hover:text-black"
                          >
                            Click here to contact HR
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className=" flex gap-x-4 items-start justify-center gap-y-2 flex-wrap  ">
                      <h1 className="text-base font-bold text-blue-200 text-center update">
                        For daily job updates, follow us on
                      </h1>
                      <div className="flex gap-x-2 justify-center items-center">
                        <a
                          href="https://chat.whatsapp.com/IyKRqBudFmg7ViOqQrJ9gr"
                          target="_blank"
                          rel="noopener noreferrer"
                          className=" w-7 h-7 rounded-full transition-all duration-300 hover:scale-110 cursor-pointer"
                        >
                          <LazyLoadImage
                            src="/whatsapp-1.png"
                            alt="whatsapp"
                            effect="blur"
                            className="w-full h-full object-cover rounded-full"
                          />
                        </a>

                        <a
                          href="https://t.me/job_at_hirers"
                          target="_blank"
                          rel="noopener noreferrer"
                          className=" w-7 h-7 rounded-full transition-all duration-300 hover:scale-110 cursor-pointer"
                        >
                          <LazyLoadImage
                            src="/teleg.png"
                            alt="whatsapp"
                            effect="blur"
                            className="w-full h-full object-cover rounded-full"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Frame12;
